import React from 'react'
import Header from '../Header/Header'

import './Layout.sass'

function Layout(props) {
  const {
    children,
    title,
    logoSrc = '',
    className = '',
    customHeaderMenu = "",
    fullScreen = false
  } = props

  return (
    <div className={className}>
      {!fullScreen &&
        <Header
          logoSrc={logoSrc}
          customHeaderMenu={customHeaderMenu}
        />
      }
      <div className={`layout ${fullScreen ? 'fullScreen' : ''}`}>
        <div className="content-wrapper">
          {title && <h1 className='d-ib'>{title}</h1>}
          {children}
        </div>
      </div>
    </div>
  );
}


export default Layout
