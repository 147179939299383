import { DateTime } from 'luxon'

const MAX_401_ATTEMPTS = 3
const MAXIMUM_IDLE_TIME = 30
const MODAL_MAXIMUM_IDLE_TIME = 15
const ONE_SECOND = 1000
const TEN_MINUTES = 600000
const REGEX_HTTP_URL = /(https?:\/\/[^\s]+)/g
const REGEX_ENDLINE = /\n/g
// eslint-disable-next-line no-useless-escape
const REGEX_EMAIL = /[a-zA-Z0-9\-]+[\._]?[a-zA-Z0-9]+[@][\w\-]+[.]\w{2,3}/

const EIGHT_AM = DateTime.local().set({ hour: 8, minute: 0, second: 0 }).toJSDate() // Js Date 
const FIVE_PM = DateTime.local().set({ hour: 17, minute: 0, second: 0 }).toJSDate() // Js Date 


const DEFAULT_FLOOR_IMAGE = { url: 'static_map.png', size: [] }
const IMAGE_BOUNDS = [[0, 0], [1000, 1000]]
const CENTER = [0, 0]
const AMENITIES = [
  'AIR_CONDITIONER',
  'BOARD',
  'PLUG',
  'PROJECTOR',
  'VIDECONFERENCE',
  'WIFI',
  'TV'
]

const TODAY = new Date()

const INITIAL_ITEM = {
  id: 0,
  absence: false,
  arrived: false,
  enabled_carpool: false,
  entry_time: TODAY,
  parking_date: "",
  parking_lot_id: 0,
  parking_space: {},
  parking_type: "",
  reason: "0",
  reason_id: 4,
  status: "APPROVED",
  type: "ParkingReservation",
  user_id: 45,
  vehicle: {}
}

export {
  MODAL_MAXIMUM_IDLE_TIME,
  DEFAULT_FLOOR_IMAGE,
  MAXIMUM_IDLE_TIME,
  MAX_401_ATTEMPTS,
  REGEX_HTTP_URL,
  REGEX_ENDLINE,
  IMAGE_BOUNDS,
  INITIAL_ITEM,
  REGEX_EMAIL,
  TEN_MINUTES,
  ONE_SECOND,
  AMENITIES,
  EIGHT_AM,
  FIVE_PM,
  CENTER,
  TODAY,
}