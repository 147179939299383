import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { setTokensForce, api } from '../../../api/api'

import {
  AppBar,
  Toolbar,
  Button,
  MenuItem,
  Menu,
  Stack
} from '@mui/material'

import {
  AccountCircle,
  ArrowDropDown as ArrowDropDownIcon,
  MoreVert as MoreVertIcon
} from '@mui/icons-material'

import { Types } from '../../../state/actionTypes'

import './Header.sass'

function Header(props) {
  const {
    profile,
    firstName,
    dispatch,
    logoSrc,
    customHeaderMenu
  } = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()
  const handleMenu = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const globalLogout = useCallback(() => {
    dispatch({ type: Types.CLEAN_DEVISE_AUTH })
    navigate('/login')
  }, [dispatch, navigate])

  const onDeleteEmployeeSession = useCallback(() => {
    api.delete('employee/impersonate')
      .then(console.log)
      .catch(console.log)
  }, [])

  const localLogOut = useCallback(() => {
    onDeleteEmployeeSession()
    setTokensForce(profile.global_user_headers)
    dispatch({ type: Types.CLEAN_GLOBAL_USER_HEADERS })

    navigate('/')
  }, [dispatch, navigate, profile.global_user_headers, onDeleteEmployeeSession])

  const logOut = useCallback(() => {
    if (location.pathname === '/reservations') localLogOut()
    else globalLogout()
  }, [globalLogout, localLogOut, location.pathname])

  const handleOption = useCallback(option => {
    if (option === 'logout') logOut()
  }, [logOut])

  const StyledMenu = styled(props => (
    <Menu
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...props}
    />
  ))(() => ({
    '& .MuiList-root ': {
      padding: '0px'
    },
    '& .MuiPaper-root': {
      borderRadius: '0 0 4px 4px',
      minWidth: 229,
      color: 'black',
      background: '#F9F9F9',
      boxShadow: '0px 0px 6px rgba(146, 146, 146, 0.5)'
    },
    '& .MuiMenuItem-root:hover': {
      background: '#D8DFF5'
    }
  }));

  const getMenu = useCallback(
    () => {
      return (
        <>
          <Button
            size="large"
            aria-label="account of current user"
            aria-controls="menu-app-bar"
            aria-haspopup="true"
            className="header-options"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
            <p className="username">
              {firstName}
            </p>
            <ArrowDropDownIcon />
          </Button>
          <StyledMenu
            id="menu-app-bar"
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => handleOption('logout')}>
              {t('logout')}
            </MenuItem>
          </StyledMenu>
        </>
      )
    },
    [anchorEl, firstName, handleOption, t],
  )

  const getCustomHeaderMenu = () => {
    return (
      <Stack direction="row">
        {customHeaderMenu()}
        <Button
          variant="text"
          aria-controls="menu-app-bar"
          className="header-logout"
          onClick={handleMenu}
          color="inherit"
        >
          <MoreVertIcon />
        </Button>
        <StyledMenu
          id="menu-app-bar"
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => handleOption('logout')}>
            {t('logout')}
          </MenuItem>
        </StyledMenu>
      </Stack>
    )
  }

  return (
    <AppBar position="static" className="header">
      <Toolbar className="header-toolbar">
        {
          <Button variant="text" >
            <img
              src={logoSrc ? logoSrc : '/logo_corporate_experience.svg'}
              alt="logo"
              className='logo-img'
            />
          </Button>
        }
        <div className='ml-auto'>
          {customHeaderMenu ?
            getCustomHeaderMenu() :
            getMenu()
          }
        </div>
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = state => {
  return {
    firstName: state.profile.selected_user.first_name,
    profile: state.profile
  }
}

export default connect(mapStateToProps)(Header)