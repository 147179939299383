import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import {
  MenuItem,
  Select,
  Stack
} from '@mui/material'

import { useTranslation } from 'react-i18next'
import './Filters.sass'

const defaultFunction = () => { }

function Filters(props) {

  const {
    site = '',
    sites = [],
    building = '',
    buildings = [],
    floor = '',
    floors = [],
    setSite = defaultFunction,
    setBuilding = defaultFunction,
    setFloor = defaultFunction,
  } = props

  const { t } = useTranslation()

  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <div className="filters">
      <Stack className="cp-stack" direction="row" spacing={2}>
        {isLarge && <label htmlFor="site">{t('site')}:</label>}
        <Select
          className="cp-select"
          name="site"
          value={site}
          displayEmpty
          onChange={e => setSite(e.target.value)}
        >
          {sites.map(s =>
            <MenuItem
              key={s.id}
              value={s.id}>
              {s.name}
            </MenuItem>
          )}
        </Select>
        {isLarge && <label htmlFor="building">{t('building')}:</label>}
        <Select
          className="cp-select"
          name="building"
          value={building}
          displayEmpty
          onChange={e => setBuilding(e.target.value)}
        >
          {buildings.map(s =>
            <MenuItem
              key={s.id}
              value={s.id}>
              {s.name}
            </MenuItem>
          )}
        </Select>
        {isLarge && <label htmlFor="floor">{t('floor')}:</label>}
        <Select
          className="cp-select"
          name="floor"
          value={floor}
          displayEmpty
          onChange={e => setFloor(e.target.value)}
        >
          {floors.map(s =>
            <MenuItem
              key={s.id}
              value={s.id}>
              {s.name}
            </MenuItem>
          )}
        </Select>
      </Stack>
    </div>
  )
}
export default Filters

