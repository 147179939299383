import { Types } from '../actionTypes'
import { clearTokens } from '../../api/api'

const defaultSelectedUSer = {
  id: '',
  first_name: '',
  last_name: '',
  email: '',
}

const initialState = {
  id: '',
  first_name: '',
  last_name: '',
  email: '',
  card_number: '',
  tag_number: '',
  beacon: '',
  team: [],
  group: [],
  next_assistance_date: '',
  preferentialAccess: false,
  domain: 'public',
  goHome: 0,
  enabled_parking: false,
  enabled_workplace: false,
  enabled_parking_schedule: false,
  enabled_visits: false,
  company: {
    id: '',
    enabled_visit_privacy_notice: false,
    max_future_days_to_reserve: 60,
    show_desk_reservation_reason: false,
    visit_custom_fields: []
  },
  lng: localStorage.getItem('lng') || 'en',
  global_user_headers: [],
  selected_user: defaultSelectedUSer,
  logo: ''
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_LANGUAGE:
      const lng = action.payload
      state.lng = lng
      return state
    case Types.SET_DOMAIN:
      const domain = action.payload
      state.domain = domain
      sessionStorage.setItem('domain', domain)
      return state
    case Types.SET_DEVISE_AUTH:
      const {
        id = '',
        first_name = '',
        last_name = '',
        email = '',
        card_number = '',
        tag_number = '',
        beacon = '',
        preferentialAccess = false
      } = action.payload.data

      const goHome = state.goHome + 1
      const company = action.payload.company
      const group = action.payload.groups
      const team = action.payload.workplace_group

      const {
        enabled_parking,
        enabled_workplace,
        enabled_parking_schedule,
        enabled_visits,
        next_assistance_date
      } = action.payload

      return {
        ...state,
        id,
        first_name,
        last_name,
        email,
        card_number,
        tag_number,
        beacon,
        company,
        team,
        group,
        preferentialAccess,
        goHome,
        enabled_parking,
        enabled_workplace,
        enabled_parking_schedule,
        enabled_visits,
        next_assistance_date
      }
    case Types.SET_GLOBAL_USER_HEADERS:
      const { global_user_headers } = action.payload
      return { ...state, global_user_headers }
    case Types.CLEAN_GLOBAL_USER_HEADERS:
      return {
        ...state,
        global_user_headers: [],
        selected_user: defaultSelectedUSer
      }
    case Types.SET_SELECTED_USER:
      {
        const {
          id = '',
          first_name = '',
          last_name = '',
          email = '',
        } = action.payload

        const selected_user = {
          id,
          first_name,
          last_name,
          email
        }

        return { ...state, selected_user }
      }
    case Types.CLEAN_SELECTED_USER:
      return {
        ...state,
        selected_user: defaultSelectedUSer
      }
    case Types.CLEAN_DEVISE_AUTH:
      clearTokens()
      return initialState
    case Types.SET_LOGO:
      const { logo } = action.payload
      return { ...state, logo }
    default:
      return state
  }
}

export default reducer;