export const Types = {
  SET_DOMAIN: 'SET_DOMAIN',
  SET_DEVISE_AUTH: 'SET_DEVISE_AUTH',
  CLEAN_DEVISE_AUTH: 'CLEAN_DEVISE_AUTH',
  SET_PARKING_REASONS: 'SET_PARKING_REASONS',
  SET_PARKING_TOTAL_RESERVATIONS: 'SET_PARKING_TOTAL_RESERVATIONS',
  SET_WORKPLACE_REASONS: 'SET_WORKPLACE_REASONS',
  SET_WORKPLACE_TOTAL_RESERVATIONS: 'SET_WORKPLACE_TOTAL_RESERVATIONS',
  SET_SNACKBAR_TOGGLE: 'SET_SNACKBAR_TOGGLE',
  SET_SNACKBAR_DATA: 'SET_SNACKBAR_DATA',
  SET_BACKDROP_TOGGLE: 'SET_BACKDROP_TOGGLE',
  SET_BACKDROP_LOADING: 'SET_BACKDROP_LOADING',
  SET_IS_ONLINE: 'SET_IS_ONLINE',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_LOGO: 'SET_LOGO',
  SET_SELECTED_USER: 'SET_SELECTED_USER',
  CLEAN_SELECTED_USER: 'CLEAN_SELECTED_USER',
  SET_WORKPLACE_OCCUPANCY: 'SET_WORKPLACE_OCCUPANCY',
  SET_GLOBAL_LOGOUT_MODAL_OPEN: 'SET_GLOBAL_LOGOUT_MODAL_OPEN',
  SET_IDLETIME: 'SET_IDLETIME',
  PUSH_IDLETIME: 'PUSH_IDLETIME',
  SET_GLOBAL_USER_HEADERS: 'SET_GLOBAL_USER_HEADERS',
  CLEAN_GLOBAL_USER_HEADERS: 'CLEAN_GLOBAL_USER_HEADERS'
}