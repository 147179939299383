import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { Types } from '../../../state/actionTypes'
import { setTokensForce } from '../../../api/api'
import { useNavigate } from 'react-router-dom'

import {
  Stack,
  Button,
  Dialog,
  DialogContent,
} from '@mui/material'

function GlobalLogoutModal(props) {
  const {
    open,
    profile,
    dispatch
  } = props

  const closeModal = useCallback(() => dispatch({
    type: Types.SET_GLOBAL_LOGOUT_MODAL_OPEN,
    payload: false
  }), [dispatch])

  const navigate = useNavigate()

  const userLogout = useCallback(() => {
    closeModal()
    setTokensForce(profile.global_user_headers)
    dispatch({ type: Types.CLEAN_GLOBAL_USER_HEADERS })
    navigate('/')
  }, [closeModal, profile.global_user_headers, dispatch, navigate])

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      aria-labelledby="responsive-dialog-title">
      <DialogContent className="dialog-global">
        <p>¿Quieres realizar otra acción?</p>
        <Stack spacing={2} direction="row" className='detail-footer'>
          <Button
            fullWidth
            variant="outlined"
            onClick={closeModal} >
            Seguir aquí
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={userLogout} >
            Quiero salir
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

const mapStateToProps = state => {
  return {
    open: state.modals.globalLogoutModalOpen,
    profile: state.profile
  }
}

export default connect(mapStateToProps)(GlobalLogoutModal)
