import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Stack,
  Button,
  Dialog,
  Collapse,
  DialogContent,
} from '@mui/material'

import {
  InfoOutlined as InfoOutlinedIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';

import useCheck from '../../utilities/customHooks/useCheck'

import './PrivacyNotification.sass'

function PrivacyNotification(props) {
  const {
    onCancel,
    onAccept,
    open,
    message = ''
  } = props

  const { t } = useTranslation()
  const [openDetail, setOpenDetail] = useCheck(false)

  return (
    <Dialog
      className='PrivacyNotification'
      maxWidth="xs"
      open={open}>
      <DialogContent>
        <Stack
          spacing={2}
          alignItems="center"
        >
          <InfoOutlinedIcon className='info-ico' sx={{ fontSize: 68 }} />
          <h1>{t("Notice of Privacy")}</h1>
          <p>{t("We respect your privacy. You can accept or reject the use of your data for informational purposes.")}</p>
          {message &&
            <>
              <Button fullWidth variant="text" className='detail-button' onClick={setOpenDetail}>
                {t("If you want to know more click here")}
                <ExpandMoreIcon className={openDetail ? 'reverse' : ''} />
              </Button>
              <Collapse in={openDetail}>
                <p
                  className='detail'
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              </Collapse>
            </>
          }
        </Stack>
        {!message && <br />}
        <Stack
          spacing={2}
          direction="row"
          className='detail-footer'>
          <Button
            size="large"
            fullWidth
            variant="outlined"
            onClick={onCancel} >
            Rechazar
          </Button>
          <Button
            size="large"
            fullWidth
            variant="contained"
            onClick={onAccept} >
            Aceptar
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default PrivacyNotification