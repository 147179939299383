import React, { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Layout from '../../common/components/Layout/Layout'
import FloorMap from '../../common/components/FloorMap/FloorMap'
import Filters from '../../common/components/Filters/Filters'
import { sortItems } from '../../common/utilities/utilities'
import { Dot } from '../../common/components/Svgs'
import VisitForm from '../../common/components/VisitIForm/VisitForm';

import {
  Stack,
  Button,
  TextField,
} from '@mui/material'

import {
  Autorenew as AutorenewIcon,
  ArrowBackIos as ArrowBackIosIcon,
} from '@mui/icons-material'

import {
  TEN_MINUTES,
  MAX_401_ATTEMPTS,
  CENTER as DEFAULT_CENTER
} from '../../variables'

import {
  setLog,
  types as errorTypes
} from '../../common/utilities/LogError'

import { Types } from '../../state/actionTypes'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { api, popTokens, setTokensForce, getTokens } from '../../api/api'

import './SearchUser.sass'


const initialFloorImage = {
  url: '',
  size: []
}

let iteration = 0
let interval = 0

function SearchUser(props) {

  const {
    logo,
    loading,
    dispatch,
    occupancy,
    companyName,
  } = props

  const INITIAL = 'INITIAL'
  const VISIT = 'VISIT'
  const RESERVATION = 'RESERVATION'

  const [selectedItem, setSelectedItem] = useState(INITIAL)
  const [showFormId, setShowFormId] = useState(true)
  const [email, setEmail] = useState('')
  const [identification, setIdentification] = useState('')
  const [notFoundError, setNotFoundError] = useState('')
  const [markers, setMarkers] = useState([])
  const [floorImage, setFloorImage] = useState(initialFloorImage)

  const [site, setSite] = useState('')
  const [sites, setSites] = useState([])
  const [building, setBuilding] = useState('')
  const [buildings, setBuildings] = useState([])
  const [floor, setFloor] = useState('')
  const [floors, setFloors] = useState([])
  const [firstCharge, setFirstCharge] = useState(true)

  const { t } = useTranslation()
  const navigate = useNavigate()
  const goBack = () => setSelectedItem(INITIAL)
  const logout = useCallback(() => {
    dispatch({ type: Types.CLEAN_DEVISE_AUTH })
    navigate('/login')
  }, [dispatch, navigate])

  const eval401Error = useCallback((error, call) => {
    iteration++
    if (iteration < MAX_401_ATTEMPTS) {
      popTokens()
      call()
    } else {
      setLog({ type: errorTypes.MAX_401_ATTEMPTS })
      logout(error)
    }
  }, [logout])

  const setLoading = useCallback(loading => {
    dispatch({
      type: Types.SET_BACKDROP_LOADING,
      payload: { loading }
    })
  }, [dispatch])

  const defaultSuccess = useCallback(r => {
    iteration = 0
    setLoading(false)
    return r
  }, [setLoading])

  const defaultCatch = useCallback((error, currentFunction) => {
    setLoading(false)
    if (error?.response?.status === 401) eval401Error(error, currentFunction)
    else if (error.response) {
      setLog({
        title: error.response.status,
        type: errorTypes.API_ERROR,
        message: error.response.data.errors,
        api_url: error.response.config.url,
        api_method: error.response.config.method,
        api_params: error.response.config.data
      })
    } else setLog({ type: errorTypes.API_ERROR, message: error.message })
  }, [eval401Error, setLoading])

  const toggleForm = useCallback(() => {
    setShowFormId(showFormId => !showFormId)
    setEmail('')
    setIdentification('')
  }, [])

  const setUserSelected = useCallback(user => {
    dispatch({
      type: Types.SET_SELECTED_USER,
      payload: user
    })
  }, [dispatch])

  const onChangeIdentification = e => {
    setIdentification(e.target.value)
    setNotFoundError('')
  }

  const fetchFloorsImage = useCallback(params => {
    const { id, width, height } = params
    setLoading(true)
    api.get(`/workplace/floor_map/serve/${id}`, { responseType: "blob" })
      .then(defaultSuccess)
      .then(({ data }) => {
        const blob = URL.createObjectURL(data)
        setFloorImage({ url: blob, size: [width, height] })
      })
      .catch(error => defaultCatch(error, () => fetchFloorsImage(params)))
  }, [defaultCatch, defaultSuccess, setLoading])

  const fetchFloorMap = useCallback(floor => {
    setLoading(true)

    api.get(`/workplace/floor/heatmap/${floor}`)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const { markers } = data.heat_map
        setMarkers(markers)
        fetchFloorsImage(data.heat_map)
      })
      .catch(error => defaultCatch(error, () => fetchFloorMap(floor)))
  }, [defaultCatch, defaultSuccess, fetchFloorsImage, setLoading])


  const fetchWorkplaceOccupancy = useCallback(floor => {
    setLoading(true)
    const params = { floor }
    api.get('workplace/occupancy', { params })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return

        const {
          user_actives,
          occupancy_percentage
        } = data

        const occupancy = {
          user_actives,
          occupancy_percentage
        }

        dispatch({
          type: Types.SET_WORKPLACE_OCCUPANCY,
          payload: { occupancy }
        })

      })
      .catch(error => defaultCatch(error, () => fetchWorkplaceOccupancy(floor)))
  }, [defaultCatch, defaultSuccess, dispatch, setLoading])

  const fetchFloorMapAndOccupancy = useCallback((floorID, floorMapID) => {
    if (interval) clearTimeout(interval)
    interval = setTimeout(() => fetchFloorMapAndOccupancy(floorID, floorMapID), TEN_MINUTES)
    fetchWorkplaceOccupancy(floorID)
    fetchFloorMap(floorMapID)
  }, [fetchFloorMap, fetchWorkplaceOccupancy])

  const fetchFloors = useCallback(building => {
    setLoading(true)
    api.get(`/workplace/floors/${building}`)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const floors = sortItems(data.floors)
        const floor = floors[0].id
        const floorMapID = floors[0].floor_map.id
        setFloors(floors)
        if (firstCharge && floor) {
          setFirstCharge(false)
          setFloor(floor)
          fetchFloorMapAndOccupancy(floor, floorMapID)
        }
      })
      .catch(error => defaultCatch(error, () => fetchFloors(building)))
  }, [defaultCatch, defaultSuccess, fetchFloorMapAndOccupancy, firstCharge, setLoading])

  const fetchBuilding = useCallback(site => {
    setLoading(true)
    api.get(`/workplace/buildings/${site}`)
      .then(defaultSuccess)
      .then(({ data }) => {
        iteration = 0
        if (!data.success) return
        const buildings = sortItems(data.buildings)
        const building = buildings[0]
        setBuildings(buildings)
        if (firstCharge && building) {
          fetchFloors(building.id)
          setBuilding(building.id)
        }
      })
      .catch(error => defaultCatch(error, () => fetchBuilding(site)))
  }, [defaultCatch, defaultSuccess, fetchFloors, firstCharge, setLoading])

  const fetchSites = useCallback(() => {
    setLoading(true)
    api.get('/workplace/sites')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const sites = sortItems(data.sites)
        if (sites.length === 0) return
        const site = sites.sort((a, b) => a.id - b.id)[0]
        setSites(sites)
        if (firstCharge) {
          fetchBuilding(site.id)
          setSite(site.id)
        }
      })
      .catch(error => defaultCatch(error, fetchSites))
  }, [defaultCatch, defaultSuccess, fetchBuilding, firstCharge, setLoading])

  const onChangeSite = useCallback(site => {
    setSite(site)
    setBuilding('')
    setFloor('')
    fetchBuilding(site)
  }, [fetchBuilding])

  const onChangeBuilding = useCallback(building => {
    setBuilding(building)
    setFloor('')
    fetchFloors(building)
  }, [fetchFloors])

  const onChangeFloor = useCallback(floor => {
    const floorMapID = floors.find(f => f.id === floor)?.floor_map.id
    setFloor(floor)
    fetchFloorMapAndOccupancy(floor, floorMapID)
  }, [fetchFloorMapAndOccupancy, floors])

  const onChangeEmail = e => {
    setEmail(e.target.value)
    setNotFoundError('')
  }

  const fetchUserToken = useCallback(id => {
    setLoading(true)
    api.get(`employee/impersonate/${id}`)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const global_user_headers = getTokens()
        dispatch({
          type: Types.SET_GLOBAL_USER_HEADERS,
          payload: { global_user_headers }
        })
        setTokensForce([data.headers])
        navigate('/reservations')
      })
      .catch(error => defaultCatch(error, () => fetchUserToken(id)))
  }, [defaultCatch, defaultSuccess, dispatch, navigate, setLoading])

  const fetchUser = useCallback(() => {
    const params = showFormId ? { identification } : { email }
    setLoading(true)
    api.get('/employee/search', { params })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const employee = data.employees[0]
        if (employee) {
          setUserSelected(employee)
          fetchUserToken(employee.id)
        } else setNotFoundError(t('Not found'))
      })
      .catch(error => defaultCatch(error, fetchUser))
  }, [defaultCatch, defaultSuccess, email, fetchUserToken, identification, setLoading, setUserSelected, showFormId, t])


  const handleEmailKeyPress = useCallback(event => {
    if (
      !email ||
      event.key !== 'Enter' ||
      loading
    ) return

    fetchUser()
  }, [email, fetchUser, loading])


  const handleIdentificationlKeyPress = useCallback(event => {
    if (
      !identification ||
      event.key !== 'Enter' ||
      loading
    ) return

    fetchUser()
  }, [identification, fetchUser, loading])

  const formId = useCallback(() => {
    return (
      <div className='form-wrapper'>
        <Stack spacing={3} >
          <TextField
            id="num-identification"
            label={t('Num. Identification')}
            variant="outlined"
            error={!!notFoundError}
            helperText={notFoundError}
            onKeyPress={handleIdentificationlKeyPress}
            value={identification}
            fullWidth
            onChange={onChangeIdentification}
          />
          <Button
            variant="text"
            size="large"
            onClick={toggleForm}
          >
            <AutorenewIcon />
            {t('Search by email')}
          </Button>
        </Stack>
      </div>
    )
  }, [handleIdentificationlKeyPress, identification, notFoundError, t, toggleForm])

  const formEmail = useCallback(() => {
    return (
      <div className='form-wrapper'>
        <Stack spacing={3} >
          <TextField
            id="email"
            label={t('email')}
            variant="outlined"
            error={!!notFoundError}
            helperText={notFoundError}
            onKeyPress={handleEmailKeyPress}
            value={email}
            fullWidth
            onChange={onChangeEmail}
          />
          <Button
            variant="text"
            size="large"
            onClick={toggleForm}
          >
            <AutorenewIcon />
            {t('Search by ID')}
          </Button>
        </Stack>
      </div>
    )
  }, [email, handleEmailKeyPress, notFoundError, t, toggleForm])

  const getForm = useCallback(() => {
    if (showFormId) return formId()
    return formEmail()
  }, [formId, formEmail, showFormId])

  const fetchLogo = useCallback(() => {
    setLoading(true)
    api.get('logo', { responseType: "blob" })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (data.type !== 'text/html') {
          const logo = URL.createObjectURL(data)
          dispatch({
            type: Types.SET_LOGO,
            payload: { logo }
          })
        }
        fetchSites()
      })
      .catch(error => defaultCatch(error, fetchLogo))
  }, [defaultCatch, defaultSuccess, dispatch, fetchSites, setLoading])

  useEffect(() => {
    fetchLogo()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCenterItem = useCallback(() => {
    const marker = markers.find(m => m.selection)
    return marker ? [marker.x, marker.y] : DEFAULT_CENTER
  }, [markers])

  const welcomeBar = useCallback(() => {
    return <Stack
      className='welcome-bar'
      spacing={3}
      alignItems="center"
      justifyContent="center"
    >
      <Button variant="text" >
        <img
          src={logo || '/logo_corporate_experience.svg'}
          alt="logo"
          className='welcome-logo-img'
        />
      </Button>
      <h1>{t('we welcome you')}</h1>
      <p className='subtitle'>Bienvenidos a la oficina de {companyName}</p>
      <p className='subtitle'>{t('Enter as:')}</p>
      <Stack
        spacing={3}
        alignItems="center"
        justifyContent="center"
        className="welcome-buttons-wrapper"
      >
        <Button
          className="big-button"
          size="large"
          variant="contained"
          onClick={() => setSelectedItem(VISIT)}
        >
          {t('Visitor')}
        </Button>
        <Button
          className="big-button"
          size="large"
          variant="contained"
          onClick={() => setSelectedItem(RESERVATION)}
        >
          {t('Collaborator')}
        </Button>
      </Stack>
    </Stack>
  }, [companyName, logo, t])

  const getFloorMap = useCallback(r => {
    if (!floorImage.url) return ''

    return (
      <FloorMap
        className="detail-list-map"
        center={getCenterItem()}
        floorImage={floorImage}
        markers={markers}
        doubleClickZoom={false}
        isHeatMap
      />
    )
  }, [floorImage, getCenterItem, markers])


  const boxOccuopancy = useCallback(() => {
    const {
      user_actives,
      occupancy_percentage
    } = occupancy

    return (
      <>
        <div className='card-total'>
          <Stack spacing={1}>
            <label>
              <Dot />
              Personal total en sitio
            </label>
            <Stack direction="row" spacing={1} alignItems="center">
              <span className='value'>{user_actives}</span>
              <span className='percentage'>{occupancy_percentage} % de ocupación</span>
            </Stack>
          </Stack>
        </div>
      </>
    )
  }, [occupancy])

  const heatMapBar = useCallback(() => {
    return (
      <Stack className="map-wrapper">
        <div className='top-info'>
          <h2>{t('Occupation on site')}</h2>
          <Stack
            direction="row"
            justifyContent="space-between"
            className='pr'>
            <Filters
              site={site}
              sites={sites}
              building={building}
              buildings={buildings}
              floor={floor}
              floors={floors}
              setSite={onChangeSite}
              setBuilding={onChangeBuilding}
              setFloor={onChangeFloor}
            />
            {boxOccuopancy()}
          </Stack>
        </div>
        {getFloorMap()}
      </Stack>
    )
  }, [building, buildings, floor, floors, getFloorMap, onChangeBuilding, onChangeFloor, onChangeSite, site, sites, boxOccuopancy, t])

  const itemInitial = useCallback(() => {
    return (
      <div className='item-carousel'>
        <Stack direction="row">
          {welcomeBar()}
          {heatMapBar()}
        </Stack>
      </div>
    )
  }, [welcomeBar, heatMapBar])

  const itemReservations = useCallback(() => {
    return (
      <div className='item-carousel form'>
        <Stack
          className='absolute-center'
          spacing={3}
          alignItems="center"
          justifyContent="center"
        >
          <Button
            className='back-button'
            variant="text"
            size="large"
            onClick={goBack}
          >
            <ArrowBackIosIcon />
            {t('back')}
          </Button>
          <h1>{t('Enter your data')}</h1>
          {getForm()}
          <Button
            disabled={!(email || identification)}
            className="big-button"
            size="large"
            onClick={fetchUser}
            variant="contained"
          >
            {t('Search')}
          </Button>
        </Stack>
      </div>
    )
  }, [email, fetchUser, getForm, identification, t])

  const getHeaderBackground = useCallback(
    () => selectedItem === INITIAL ? 'bigHeader' : '',
    [selectedItem]
  )

  return (
    <div className="SearchUser">
      <Layout
        logoSrc={logo}
        fullScreen={selectedItem === INITIAL}
        className={getHeaderBackground()}
      >
        {selectedItem === INITIAL && itemInitial()}
        {selectedItem === VISIT && <VisitForm goBack={goBack} />}
        {selectedItem === RESERVATION && itemReservations()}
      </Layout>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    companyName: state.profile.company.name,
    logo: state.profile.logo,
    loading: state.backdrop.loading,
    occupancy: state.workplace.occupancy
  }
}

export default connect(mapStateToProps)(SearchUser)