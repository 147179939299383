import { Types } from "../actionTypes"
import { api, setTokens } from '../../api/api'

let global_baseURL = ''
let global_dispatch = {}

const cleanPayload = data => {
  const token = data.tokens[0] || {}

  const {
    enabled_parking = false,
    enabled_workplace = false,
    enabled_parking_schedule = false,
    enabled_visits = false
  } = token

  return {
    ...data,
    enabled_parking,
    enabled_workplace,
    enabled_parking_schedule,
    enabled_visits
  }
}

const deviseAuth = (payload, baseURL) => {
  return function (dispatch) {
    return api.post('/auth/sign_in', payload, { baseURL })
      .then(({ data }) => {
        setTokens(data.tokens[0].token)
        dispatch({
          type: Types.SET_DEVISE_AUTH,
          payload: cleanPayload(data)
        })
      })
  }
}

const setLoading = loading => {
  return function (dispatch) {
    dispatch({
      type: Types.SET_BACKDROP_LOADING,
      payload: { loading }
    })
  }
}

const setSnackbar = payload => {
  return function (dispatch) {
    dispatch({
      type: Types.SET_SNACKBAR_DATA,
      payload
    })
  }
}

const setDomain = domain => {
  return function (dispatch) {
    dispatch({
      type: Types.SET_DOMAIN,
      payload: domain
    })
  }
}

const ldapAuth = ({ password }, data) => {
  return function (dispatch) {
    const { user_name, provider } = data.auth_types[0]
    const _data = { user_name, provider, password }
    return api.post('/users/ldap/sign_in', _data)
      .then(({ data }) => {
        const { success, errors } = data
        if (!success) {
          if (errors === 'Usuario/contraseña inválido') {
            throw new Error('password is incorrect')
          }
          throw new Error('unknown error')
        }
        setTokens(data.tokens[0].token)
        dispatch({
          type: Types.SET_DEVISE_AUTH,
          payload: cleanPayload(data)
        })
      })
  }
}

const messageListener = event => {
  const { data, origin } = event
  const url = global_baseURL.replace('/api', '')
  if (origin !== url) return
  window.global_tab?.close()
  window.removeEventListener("message", messageListener)

  const {
    access_token,
    client,
    uid
  } = data

  setTokens({
    'access-token': access_token,
    'client': client,
    'token-type': 'Bearer',
    'uid': uid
  })

  const config = {
    headers: {
      'access-token': access_token,
      'client': client,
      'token-type': 'Bearer',
      'uid': uid
    }
  }

  api.get('/auth/validate_token', {}, config)
    .then(({ data }) => {
      global_dispatch({
        type: Types.SET_DEVISE_AUTH,
        payload: cleanPayload(data)
      })
    })
}

const ssoAuth = (data, baseURL) => {
  return function (dispatch) {
    const { provider = '' } = data
    const route = `/users/saml/sign_in?web=true&provider=${provider}`
    global_baseURL = baseURL
    global_dispatch = dispatch
    window.addEventListener("message", messageListener, false)
    return `${baseURL}${route}`
  }
}

export {
  ldapAuth,
  ssoAuth,
  deviseAuth,
  setLoading,
  setSnackbar,
  setDomain
}