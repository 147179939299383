import { DateTime } from "luxon"

const formatAMPM = (date, format = 'string') => {
  if (!(date instanceof Date) || isNaN(date)) return ''
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let amPm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0' + minutes : minutes
  if (format === 'array') return [hours, minutes, amPm]
  return hours + ':' + minutes + ' ' + amPm
}
const removeTimezoneFromISO = time => time.slice(0, -6)
const removeDateFromDateTimeISO = time => time.split('T')[1]

const formatDateObj = date => DateTime.fromISO(date)  // DateTime obj
const formatDateToJS = date => DateTime.fromISO(date).toJSDate()  // Js Date 
const formatDateMed = date => DateTime.fromISO(date).toLocaleString(DateTime.DATE_HUGE) // Friday, October 14, 1983
const formatDateMedMed = date => DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED) //  Oct 14, 1983
const formatDateISOShort = date => DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT) //13/05/2022

const formatDate = date => DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT)
const formatDateToMed = date => DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_MED) // Oct 14, 1983
const formatDateToISOShort = date => DateTime.fromJSDate(date).toISODate() // '1982-05-25'
const formatDateToISO = date => removeTimezoneFromISO(DateTime.fromJSDate(date).toISO()) // '2017-04-22T20:47:05.335'

const formatTime = time => formatAMPM(DateTime.fromISO(time).toJSDate()) // 11:55 am
const formatTimeFromJS = time => formatAMPM(time) // 11:55 am
const formatTimeFromJSArray = time => formatAMPM(time, 'array') // [11, 55, "am"]
const formatDateToTimeISO = time => removeDateFromDateTimeISO(removeTimezoneFromISO(DateTime.fromJSDate(time).toISO())) // '20:47:05.335'
const formatDateToTimeHour = time => DateTime.fromJSDate(time).hour // '20'
const clearTimeFromISO = time => removeDateFromDateTimeISO(removeTimezoneFromISO(time)) // '20:47:05.335'

const getAllTime = () => {
  const start = clearTimeFromISO(DateTime.local().set({ hour: 0, minute: 0, second: 0 }).toISO())
  const end = clearTimeFromISO(DateTime.local().set({ hour: 23, minute: 59, second: 59 }).toISO())
  return { start, end }
}

const isAllDay = ({ entry_time, exit_time }) => {
  const _entry_time = formatDateToJS(entry_time)
  const _exit_time = formatDateToJS(exit_time)

  let entry_hour = _entry_time.getHours()
  let entry_minute = _entry_time.getMinutes()
  let exit_hour = _exit_time.getHours()
  let exit_minute = _exit_time.getMinutes()

  return (
    entry_hour === 0 &&
    entry_minute === 0 &&
    exit_hour === 23 &&
    exit_minute === 59
  )
}

export {
  isAllDay,
  getAllTime,
  formatDateObj,
  formatDateMed,
  formatDateMedMed,
  formatDateToJS,
  formatDateISOShort,
  formatDate,
  formatDateToMed,
  formatDateToISOShort,
  formatDateToISO,
  formatTime,
  formatTimeFromJS,
  formatDateToTimeHour,
  formatTimeFromJSArray,
  formatDateToTimeISO,
  removeTimezoneFromISO,
  removeDateFromDateTimeISO,
}