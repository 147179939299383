import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter, useLocation, Navigate, useNavigate } from "react-router-dom"
import { connect } from "react-redux"
import NewReservation from './pages/NewReservationPage/NewReservation'
import SearchUser from './pages/SearchUserPage/SearchUser'
import Reservations from './pages/ReservationsPage/Reservations'
import NotFound from './pages/NotFoundPage/NotFound'
import GlobalLogoutModal from './common/components/GlobalLogoutModal/GlobalLogoutModal'
import { MAXIMUM_IDLE_TIME, ONE_SECOND, MODAL_MAXIMUM_IDLE_TIME } from './variables'
import { Types } from './state/actionTypes'

import Login from './pages/LoginPage/Login'
import { clearTokens, setTokensForce } from './api/api'

const mapStateToProps = state => {
  return {
    profile: state.profile,
    email: state.profile.email,
    selectedEmail: state.profile.selected_user.email,
    loading: state.backdrop.loading,
    idleTime: state.utilities.idleTime
  }
}

function Auth({ children, email }) {
  let location = useLocation()
  if (!email) {
    clearTokens()
    return <Navigate to="/login" state={{ from: location }} />
  }
  return children
}

var _idleTime = 0
var _loading = false

function ImpersonateAuth(props) {
  const { children, email, selectedEmail, loading, dispatch, idleTime, profile } = props
  const location = useLocation()
  const navigate = useNavigate()
  _idleTime = idleTime
  _loading = loading

  const openGlobalModal = open => {
    dispatch({
      type: Types.SET_GLOBAL_LOGOUT_MODAL_OPEN,
      payload: open
    })
  }

  const timerIncrement = () => {
    if (_loading) resetIdleTime()
    else dispatch({ type: Types.PUSH_IDLETIME })

    if (_idleTime > MAXIMUM_IDLE_TIME) {
      openGlobalModal(false)
      setTokensForce(profile.global_user_headers)
      dispatch({ type: Types.CLEAN_GLOBAL_USER_HEADERS })
      navigate('/')
    } else if (_idleTime > MODAL_MAXIMUM_IDLE_TIME) {
      openGlobalModal(true)
    }
  }

  const resetIdleTime = () => {
    dispatch({
      type: Types.SET_IDLETIME,
      payload: 0
    })
  }

  useEffect(() => {
    window.addEventListener('click', resetIdleTime)
    window.addEventListener('keypress', resetIdleTime)
    const idleInterval = setInterval(timerIncrement, ONE_SECOND)

    return () => {
      clearInterval(idleInterval)
      resetIdleTime()
      window.removeEventListener('click', resetIdleTime)
      window.removeEventListener('keypress', resetIdleTime)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!email) {
    clearTokens()
    return <Navigate to="/login" state={{ from: location }} />
  }

  if (!selectedEmail) {
    return <Navigate to="/" state={{ from: location }} />
  }

  return children
}

const RequireAuth = connect(mapStateToProps)(Auth)
const RequireImpersonateAuth = connect(mapStateToProps)(ImpersonateAuth)

function RoutingSystem() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<RequireAuth><SearchUser /></RequireAuth>} />
        <Route path="/reservations" element={<RequireImpersonateAuth><Reservations /></RequireImpersonateAuth>} />
        <Route path="/new-reservation" element={<RequireImpersonateAuth><NewReservation /></RequireImpersonateAuth>} />
        <Route path='*' exact={true} element={<NotFound />} />
      </Routes>
      <GlobalLogoutModal />
    </BrowserRouter>
  )
}

export default RoutingSystem
