import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { api, popTokens } from '../../../api/api'
import { debounce, BootstrapInput, parseMessageHtml } from '../../utilities/utilities'
import { connect } from 'react-redux'

import MenuItemDefault from '../MenuItemDefault'

import {
  Grid,
  Stack,
  Button,
  Select,
  Divider,
  Checkbox,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Autocomplete,
  FormControlLabel,
  createFilterOptions,
} from '@mui/material'

import {
  ArrowBackIos as ArrowBackIosIcon,
  CheckCircle as CheckCircleIcon
} from '@mui/icons-material'

import {
  setLog,
  types as errorTypes
} from '../../../common/utilities/LogError'

import {
  MAX_401_ATTEMPTS, ONE_SECOND
} from '../../../variables'

import { Types } from '../../../state/actionTypes'


import './VisitForm.sass'
import PrivacyNotification from '../PrivacyNotification/PrivacyNotification'



let iteration = 0
let identificationSearched = ''

const filter = createFilterOptions();

function VisitForm(props) {
  const {
    dispatch,
    goBack,
    company,
    logo,
    enabledVisitPrivacyNotice,
    visitCustomFields
  } = props;

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [origins, setOrigins] = useState([])
  const [item, setItem] = useState('form')
  const [identification, setIdentification] = useState('')
  const [name, setName] = useState('')
  const [lastname, setLastname] = useState('')
  const [lastname2, setLastname2] = useState('')
  const [origin, setOrigin] = useState(null)
  const [invitation, setInvitation] = useState({})
  const [visitCard, setVisitCard] = useState('')
  const [disabledVisitCard, setDisabledVisitCard] = useState(false)
  const [electronicDevice, setElectronicDevice] = useState('')
  const [disabledELectronicDevice, setDisabledELectronicDevice] = useState(false)
  const [vCFields, setVCFields] = useState(visitCustomFields.map(title => ({ title, value: '' })))
  const [privacyMessage, setPrivacyMessage] = useState('')
  const [openPrivacyModal, setOpenPrivacyModal] = useState(enabledVisitPrivacyNotice)
  const [buildings, setBuildings] = useState([])
  const [building, setBuilding] = useState()

  const setLoading = useCallback(loading => {
    dispatch({
      type: Types.SET_BACKDROP_LOADING,
      payload: { loading }
    })
  }, [dispatch])

  const logout = useCallback(() => {
    dispatch({ type: Types.CLEAN_DEVISE_AUTH })
    navigate('/login')
  }, [dispatch, navigate])

  const eval401Error = useCallback((error, call) => {
    iteration++
    if (iteration < MAX_401_ATTEMPTS) {
      popTokens()
      call()
    } else {
      setLog({ type: errorTypes.MAX_401_ATTEMPTS })
      logout(error)
    }
  }, [logout])

  const defaultCatch = useCallback((error, currentFunction) => {
    setLoading(false)
    if (error?.response?.status === 401) eval401Error(error, currentFunction)
    else if (error.response) {
      setLog({
        title: error.response.status,
        type: errorTypes.API_ERROR,
        message: error.response.data.errors,
        api_url: error.response.config.url,
        api_method: error.response.config.method,
        api_params: error.response.config.data
      })
    } else setLog({ type: errorTypes.API_ERROR, message: error.message })
  }, [eval401Error, setLoading])

  const defaultSuccess = useCallback(
    r => {
      iteration = 0;
      setLoading(false)
      return r
    }
    , [setLoading])


  const loadPrivacyMessage = () => {
    if (!enabledVisitPrivacyNotice) return
    setLoading(true)
    api.get('/privacy/visits')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        setPrivacyMessage(parseMessageHtml(data.privacy_message))

      })
      .catch(error => defaultCatch(error, loadPrivacyMessage)
      )
  }

  const fetchBuildings = () => {
    setLoading(true)
    api.get('/buildings')
      .then(defaultSuccess)
      .then(({ data }) => {
        const { buildings = [] } = data
        setBuildings(buildings)
        loadPrivacyMessage()
      })
      .catch(error => defaultCatch(error, fetchBuildings))
  }

  /* eslint-disable */
  useEffect(fetchBuildings, [])

  const onChangeDisabledVisitCard = e => {
    setVisitCard('')
    setDisabledVisitCard(e.target.checked)
  }

  const onChangeDisabledElectronicDevice = e => {
    setElectronicDevice('')
    setDisabledELectronicDevice(e.target.checked)
  }

  const enabledFinishRegistration = useCallback(() => {
    return (
      identification &&
      name &&
      lastname &&
      origin &&
      (disabledELectronicDevice || electronicDevice) &&
      (disabledVisitCard || visitCard)
    )
  }, [identification, name, lastname, origin, disabledELectronicDevice, electronicDevice, disabledVisitCard, visitCard])

  const save = useCallback(() => {

    const visit = {
      identification: identification,
      first_name: name,
      last_name_one: lastname,
      last_name_two: lastname2,
      company_id: company.id,
      comes_from: origin,
      has_laptop: !disabledELectronicDevice,
      building_id: building
    }

    if (vCFields.length)
      visit.custom_fields = vCFields.map(f => f.value)

    const payload = { visit }

    if (!disabledVisitCard)
      payload.visit.card_number = visitCard
    if (!disabledELectronicDevice)
      payload.visit.laptop_serie = electronicDevice

    setLoading(true)
    api.post('/visits/register', payload)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        if (data.invitation) setInvitation(data.invitation)
        setItem('success')
      })
      .catch(error => defaultCatch(error, save))

  }, [identification, name, lastname, lastname2, company.id, origin, disabledELectronicDevice, vCFields, disabledVisitCard, visitCard, electronicDevice, setLoading, defaultSuccess, defaultCatch])

  const searchOrigins = useCallback(prefix => {
    setLoading(true)
    const params = { prefix }
    api.get('/visits/comes_from', { params })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.succes) return
        let { companies = [] } = data
        companies = companies.slice(0, 10)
        const companiesSet = new Set(companies)
        setOrigins([...companiesSet])
      })
      .catch(error => defaultCatch(error, () => searchOrigins(prefix)))
  }, [defaultCatch, defaultSuccess, setLoading])

  const efficenceSearchOrigins = debounce(searchOrigins, ONE_SECOND)

  const onInputChangeOrigin = useCallback((_, origin, reason) => {
    if (reason === 'input') efficenceSearchOrigins(origin)
  }, [efficenceSearchOrigins])

  const onChangeOrigin = useCallback((_, newOrigin) => {
    if (typeof newOrigin === 'string') setOrigin(newOrigin)
    else if (newOrigin && newOrigin.inputValue) setOrigin(newOrigin.inputValue)
    else setOrigin(newOrigin)
  }, [])

  const onFilterOrigins = useCallback((options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    if (options.length === 0 && inputValue) filtered.push(inputValue);
    return filtered;
  }, [])

  const onGetOptionOrigins = useCallback(option => {
    if (typeof option === 'string') return option
    if (option.inputValue) return option.inputValue
    return option
  }, [])

  const autoSetVisit = useCallback((visit = {}) => {
    const {
      first_name = '',
      last_name_one = '',
      last_name_two = '',
    } = visit
    setName(first_name)
    setLastname(last_name_one)
    setLastname2(last_name_two)
  }, [])

  const searchVisit = useCallback(identification => {
    if (
      identification === '' ||
      identificationSearched === identification
    ) return

    setLoading(true)
    const visit = { identification }
    api.post('/visits', { visit })
      .then(defaultSuccess)
      .then(({ data }) => {
        identificationSearched = identification
        if (!data.success) return
        if (data.visit) autoSetVisit(data.visit)
      })
      .catch(error => defaultCatch(error, () => searchVisit(identification)))
  }, [autoSetVisit, defaultCatch, defaultSuccess, setLoading])

  const updateCustomFieldValue = useCallback((e, i) => {
    const value = e.target.value;
    const _vCFields = [...vCFields]
    _vCFields[i].value = value
    setVCFields(_vCFields)
  }, [vCFields])

  const getFormVisit = useCallback(() => {
    return (
      <>
        <Grid
          className="visit-form-grid"
          justifyContent="space-between"
          container
          spacing={2}
        >
          <Grid xs={3} item>
            <p className='secction-title'>{t("Personal information")}</p>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid xs item className='visit-form-grid-form'>
            <Stack spacing={2}>
              <label className='info-label'>{t('Required data')} * </label>
              <FormControl variant="standard">
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input">
                  *{t('Identification')}
                </InputLabel>
                <BootstrapInput
                  value={identification}
                  onBlur={() => searchVisit(identification)}
                  onChange={e => setIdentification(e.target.value)}
                />
              </FormControl>
              <FormControl variant="standard">
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input">
                  *{t('name')}
                </InputLabel>
                <BootstrapInput
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </FormControl>
              <FormControl variant="standard">
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input">
                  *{t('last name')} 1
                </InputLabel>
                <BootstrapInput
                  value={lastname}
                  onChange={e => setLastname(e.target.value)}
                />
              </FormControl>
              <FormControl variant="standard">
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input">
                  {t('last name')} 2
                </InputLabel>
                <BootstrapInput
                  value={lastname2}
                  onChange={e => setLastname2(e.target.value)}
                />
              </FormControl>
              {vCFields.map((f, i) =>
                <FormControl variant="standard" key={i}>
                  <InputLabel
                    shrink
                    htmlFor="bootstrap-input">
                    {f.title}
                  </InputLabel>
                  <BootstrapInput
                    value={f.value}
                    onChange={e => updateCustomFieldValue(e, i)}
                  />
                </FormControl>
              )}
            </Stack>
          </Grid>
        </Grid>
        <Grid
          className="visit-form-grid"
          justifyContent="space-between"
          container
          spacing={2}
        >
          <Grid xs={3} item>
            <p className='secction-title'>{t("Entry Information")}</p>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid xs item className='visit-form-grid-form'>
            <Stack spacing={2}>
              <div>
                <InputLabel
                  shrink
                  htmlFor="comes-from">
                  *{t("Comes from")}
                </InputLabel>
                <Autocomplete
                  className='autocomplete'
                  value={origin}
                  options={origins}
                  variant="outlined"
                  onChange={onChangeOrigin}
                  filterOptions={onFilterOrigins}
                  onInputChange={onInputChangeOrigin}
                  getOptionLabel={onGetOptionOrigins}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  freeSolo
                  sx={{ width: 220 }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                    />
                  }
                />
              </div>
              <FormControl variant="standard">
                <InputLabel
                  shrink
                  htmlFor="building">
                  {t('building')}
                </InputLabel>
                <Select
                  className='visit-form-select'
                  labelId="building-label"
                  value={building}
                  input={<BootstrapInput />}
                  onChange={e => setBuilding(e.target.value)}
                >
                  <MenuItemDefault />
                  {buildings.map(b =>
                    <MenuItem
                      key={b.id}
                      value={b.id}>
                      {b.name}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl variant="standard">
                <InputLabel
                  shrink
                  htmlFor="destination">
                  {t('Destination')}
                </InputLabel>
                <BootstrapInput
                  disabled
                  value={company.name}
                />
              </FormControl>
              <FormControl variant="standard">
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input">
                  {t("Visitor card number")}
                </InputLabel>
                <BootstrapInput
                  disabled={disabledVisitCard}
                  value={visitCard}
                  onChange={e => setVisitCard(e.target.value)}
                />
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={disabledVisitCard}
                      onChange={onChangeDisabledVisitCard}
                    />
                  }
                  label={t("Omit visitor card")}
                  labelPlacement="end"
                />
              </FormControl>
              <FormControl variant="standard">
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input">
                  {t("Electronic device (Serial No.)")}
                </InputLabel>
                <BootstrapInput
                  disabled={disabledELectronicDevice}
                  value={electronicDevice}
                  onChange={e => setElectronicDevice(e.target.value)}
                />
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={disabledELectronicDevice}
                      onChange={onChangeDisabledElectronicDevice}
                    />}
                  label={t("Without electronic device")}
                  labelPlacement="end"
                />
              </FormControl>
              <Button
                variant="contained"
                className='finish-registration'
                onClick={save}
                disabled={!enabledFinishRegistration()}
              >
                {t('finish registration')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </>
    )
  }, [company.name, disabledELectronicDevice, disabledVisitCard, electronicDevice, enabledFinishRegistration, identification, lastname, lastname2, name, onChangeOrigin, onFilterOrigins, onGetOptionOrigins, onInputChangeOrigin, origin, origins, save, searchVisit, t, updateCustomFieldValue, vCFields, visitCard])

  const itemForm = useCallback(() => {
    return (
      <div>
        <div className='left-bar'>
          <img
            src={logo ? logo : '/logo_corporate_experience.svg'}
            alt="logo"
            className='absolute-center'
          />
        </div>
        <Stack
          className='form-bar'
          spacing={3}
          alignItems="left"
          justifyContent="center"
        >
          <div>
            <Button
              className='back-button'
              variant="text"
              size="large"
              onClick={goBack}
            >
              <ArrowBackIosIcon />
              {t('back')}
            </Button>
            <h1>{t('Entry as a visitor')}</h1>
          </div>
          <p className='subtitle'>
            {t('Please complete the following form:')}
          </p>
          {getFormVisit()}
        </Stack>
      </div>
    )
  }, [getFormVisit, goBack, logo, t])


  const itemSuccess = useCallback(() => {
    return (
      <div className='success-box absolute-center'>
        <CheckCircleIcon className="success-icon" />
        <h1>{t("We have registered your arrival")}</h1>
        <p>{t(
          "username, hostname will be here for you in a few minutes.",
          { username: name, hostname: invitation.name || t('the host') }
        )}
        </p>
        <Button
          onClick={goBack}
          variant="outlined"
          size="large"
        >
          {t('Agreed')}
        </Button>
      </div>
    )
  }, [t, name, invitation.name, goBack])

  return (
    <div
      id='VisitForm'
      className='item-carousel form'
    >
      {item === 'form' ?
        itemForm() :
        itemSuccess()
      }

      <PrivacyNotification
        open={openPrivacyModal}
        message={privacyMessage}
        onCancel={goBack}
        onAccept={() => setOpenPrivacyModal(false)}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    company: state.profile.company,
    enabledVisitPrivacyNotice: state.profile.company.enabled_visit_privacy_notice,
    visitCustomFields: state.profile.company.visit_custom_fields || [],
    logo: state.profile.logo
  }
}

export default connect(mapStateToProps)(VisitForm)


