import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { connect } from "react-redux"
import { useTranslation } from 'react-i18next'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Layout2 from '../../common/components/LayoutCreate/LayoutCreate'
import FloorMap from '../../common/components/FloorMap/FloorMap'
import { sortItems } from '../../common/utilities/utilities'
import { useNavigate } from 'react-router-dom'
import { api, popTokens } from '../../api/api'
import { Types } from '../../state/actionTypes'

import {
  IconButton,
  Button,
  Step,
  StepLabel,
  Stack,
  Radio,
  DialogContent,
  Dialog,
  Divider,
  MenuList,
  ListItemText,
  ListItemIcon,
  TextField,
  Stepper,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Grid,
  useMediaQuery,
  useTheme
} from '@mui/material'

import {
  LocationCity as LocationCityIcon,
  AirlineSeatReclineNormal as AirlineSeatReclineNormalIcon,
  MeetingRoomRounded as MeetingRoomRoundedIcon,
  WatchLater as WatchLaterIcon,
  Close as CloseIcon,
  Event as EventIcon,
  AccessTime as AccessTimeIcon,
  PlaceOutlined as PlaceOutlinedIcon,
  CheckCircle as CheckCircleIcon,
  InfoOutlined as InfoOutlinedIcon,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material'


import DeskDot from '../../common/components/Svgs/DeskDot'

import {
  getAllTime,
  formatDate,
  formatDateToMed,
  formatDateISOShort,
  formatDateToISOShort,
  formatDateToTimeISO,
  formatTimeFromJS
} from '../../common/utilities/FormatDate'

import { getTime } from '../../common/utilities/utilities'

import {
  setLog,
  types as errorTypes
} from '../../common/utilities/LogError'

import {
  reasonI18n,
  errorCreateReservationWorkplaceI18n
} from '../../common/utilities/Dictionary'

import './NewReservation.sass'
import './SuccessReservation.sass'
import SwipeableTimePicker from '../../common/components/SwipeableTimePicker/SwipeableTimePicker'

import {
  MAX_401_ATTEMPTS,
  EIGHT_AM,
  FIVE_PM
} from '../../variables'

let iteration = 0
const iconSx = { color: '#8C8CA1', fontSize: 15 }
const iconSelected = { color: '#75D385', fontSize: 14 }
const today = new Date()
const steps = [0, 1, 2]


function NewReservations(props) {
  const {
    dispatch,
    enabled_workplace,
    workplaceTotalReservations,
    workplaceMaxBookingPerPerson,
  } = props

  const { t } = useTranslation()
  const DEFAULT_MARKER = useMemo(() => ({ id: -1, name: t('select') }), [t])
  const navigate = useNavigate()
  const inputDateRef = useRef()
  const inputHourRef = useRef()
  const selectSiteRef = useRef()

  const [activeStep, setActiveStep] = useState(0)
  const [selectedType, setSelectedType] = useState('desk')
  const [successItem, setSuccessItem] = useState(null)
  const [openTimePicker, setOpenTimePicker] = useState(false)
  const [timePickerValue, setTimePickerValue] = useState(EIGHT_AM)
  const [exitTimePickerValue, setExitTimePickerValue] = useState(FIVE_PM)
  const [openMaxReservation, setOpenMaxReservation] = useState(false)
  const [maximumOccupancyFloor, setMaximumOccupancyFloor] = useState(false)

  const [date] = useState(today)
  const [entry_time, setEntryTime] = useState(EIGHT_AM)
  const [exit_time, setExitTime] = useState(FIVE_PM)

  // Workplace reservation
  const [site, setSite] = useState(-1)
  const [sites, setSites] = useState([])
  const [building, setBuilding] = useState(-1)
  const [buildings, setBuildings] = useState([])
  const [floor, setFloor] = useState(-1)
  const [floors, setFloors] = useState([])
  const [desk, setDesk] = useState(DEFAULT_MARKER)
  const [selectedMarker, setSelectedMarker] = useState(DEFAULT_MARKER)
  const [allTime, setAllTime] = useState(false)
  const [allTimeValue, setAllTimeValue] = useState(false)
  const [workplaceReason, setWorkplaceReason] = useState(-1)
  const [workplaceReasons, setWorkplaceReasons] = useState([])
  const [markers, setMarkers] = useState([])
  const [floorImage, setFloorImage] = useState({})
  const [openMap, setOpenMap] = useState(false)

  const [doYouWantToEdit, setDoYouWantToEdit] = useState(false)

  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))

  const setLoading = useCallback(loading => {
    dispatch({
      type: Types.SET_BACKDROP_LOADING,
      payload: { loading }
    })
  }, [dispatch])

  const logout = useCallback(() => {
    dispatch({ type: Types.CLEAN_DEVISE_AUTH })
    navigate('/login')
  }, [dispatch, navigate])

  const eval401Error = useCallback((error, call) => {
    iteration++
    if (iteration < MAX_401_ATTEMPTS) {
      popTokens()
      call()
    } else {
      setLog({ type: errorTypes.MAX_401_ATTEMPTS })
      logout(error)
    }
  }, [logout])

  const defaultCatch = useCallback((error, currentFunction) => {
    setLoading(false)
    if (error?.response?.status === 401) eval401Error(error, currentFunction)
    else if (error.response) {
      setLog({
        title: error.response.status,
        type: errorTypes.API_ERROR,
        message: error.response.data.errors,
        api_url: error.response.config.url,
        api_method: error.response.config.method,
        api_params: error.response.config.data
      })
    } else setLog({ type: errorTypes.API_ERROR, message: error.message })
  }, [eval401Error, setLoading])

  const defaultSuccess = useCallback(
    r => {
      iteration = 0;
      setLoading(false)
      return r
    }
    , [setLoading])

  const fetchWorkplaceReasons = useCallback(() => {
    setLoading(true)
    api.get('/workplace/desk_reservations_reasons')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        setWorkplaceReasons(data.reasons)
        if (data.reasons.length > 1) setWorkplaceReason(1)
      })
      .catch(error => defaultCatch(error, fetchWorkplaceReasons))
  }, [defaultCatch, defaultSuccess, setLoading])

  const fetchSites = useCallback(() => {
    setLoading(true)
    api.get('/workplace/sites')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const sites = sortItems(data.sites)
        setSites(sites)
        fetchWorkplaceReasons()
      })
      .catch(error => defaultCatch(error, fetchSites))
  }, [defaultCatch, defaultSuccess, fetchWorkplaceReasons, setLoading])

  const fetchBuilding = site => {
    setLoading(true)
    api.get(`/workplace/buildings/${site}`)
      .then(defaultSuccess)
      .then(({ data }) => {
        iteration = 0
        if (!data.success) return
        const buildings = sortItems(data.buildings)
        setBuildings(buildings)
      })
      .catch(error => defaultCatch(error, () => fetchBuilding(site)))
  }

  const fetchFloors = building => {
    setLoading(true)
    api.get(`/workplace/floors/${building}`)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        const floors = sortItems(data.floors)
        setFloors(floors)
      })
      .catch(error => defaultCatch(error, () => fetchFloors(building)))
  }

  const evalMarkerOccupancy = markers => {
    if (!markers.length) return
    const occupiedMarkers = markers.filter(m => m.occupied)
    setMaximumOccupancyFloor(occupiedMarkers.length === markers.length)
  }

  const fetchFloorMap = floor => {
    setLoading(true)

    const _date = formatDateToISOShort(date)
    const start_hour = formatDateToTimeISO(entry_time)
    const end_hour = formatDateToTimeISO(exit_time)

    const params = {
      date: _date,
      start_hour,
      end_hour
    }

    api.get(`/workplace/floor_map/${floor}`, { params })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return

        const markers = selectedType === 'desk' ?
          data.floor_map.desks :
          data.floor_map.rooms

        evalMarkerOccupancy(markers)
        setMarkers(markers)
        fetchFloorsImage(data.floor_map)
      })
      .catch(error => defaultCatch(error, () => fetchFloorMap(floor)))
  }

  const fetchFloorsImage = params => {
    const { id, width, height } = params
    setLoading(true)
    api.get(`/workplace/floor_map/serve/${id}`, { responseType: "blob" })
      .then(defaultSuccess)
      .then(({ data }) => {
        const blob = URL.createObjectURL(data)
        setFloorImage({ url: blob, size: [width, height] })
      })
      .catch(error => defaultCatch(error, () => fetchFloorsImage(params)))
  }

  useEffect(() => {
    if (activeStep !== 1) return
    fetchSites()
  }, [activeStep, fetchSites, selectedType])

  const getPlaceByID = item => {
    const { site, building, floor } = item
    const nameSite = sites.find(s => s.id === site).name
    const nameBuilding = buildings.find(b => b.id === building).name
    const nameFloor = floors.find(f => f.id === floor).name

    return `${nameSite}, ${nameBuilding}, ${nameFloor}`
  }

  const saveReservationWorkplace = () => {
    setLoading(true)

    let _entry_time = formatDateToTimeISO(entry_time)
    let _exit_time = formatDateToTimeISO(exit_time)

    if (allTime) {
      const { start, end } = getAllTime()
      _entry_time = start
      _exit_time = end
    }

    const payload = {
      desk_reservation: {
        reservation_date: formatDateToISOShort(date),
        desk_id: desk.id,
        reason: reasonI18n(workplaceReason),
        entry_time: _entry_time,
        exit_time: _exit_time
      }
    }
    api.post('/workplace/desk_reservation', payload)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success && data.message) {
          dispatch({
            type: Types.SET_SNACKBAR_DATA,
            payload: {
              open: true,
              message: errorCreateReservationWorkplaceI18n(data.message),
              severity: 'error',
              autoHideDuration: 6000
            }
          })
        } else {
          data.desk_reservation.site = site
          data.desk_reservation.building = building
          data.desk_reservation.floor = floor
          data.desk_reservation.desk = desk
          setSuccessItem(data.desk_reservation)
          setActiveStep(2)
        }
      })
      .catch(error => defaultCatch(error, saveReservationWorkplace))
  }

  const saveReservationRoom = () => {
    setLoading(true)

    let _entry_time = formatDateToTimeISO(entry_time)
    let _exit_time = formatDateToTimeISO(exit_time)

    if (allTime) {
      const { start, end } = getAllTime()
      _entry_time = start
      _exit_time = end
    }

    const payload = {
      room_reservation: {
        reservation_date: date,
        room_id: desk.id,
        reason: reasonI18n(workplaceReason),
        entry_time: _entry_time,
        exit_time: _exit_time
      }
    }
    api.post('/workplace/room_reservation', payload)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success && data.message) {
          dispatch({
            type: Types.SET_SNACKBAR_DATA,
            payload: {
              open: true,
              message: errorCreateReservationWorkplaceI18n(data.message),
              severity: 'error',
              autoHideDuration: 6000
            }
          })
        } else {
          data.room_reservation.site = site
          data.room_reservation.building = building
          data.room_reservation.floor = floor
          data.room_reservation.room = desk
          setSuccessItem(data.room_reservation)
          setActiveStep(2)
        }
      })
      .catch(error => defaultCatch(error, saveReservationRoom))
  }

  const isEnabledWorkplace = useCallback(() => {
    return (
      date &&
      entry_time &&
      exit_time &&
      site > -1 &&
      building > -1 &&
      floor > -1 &&
      desk &&
      workplaceReason > -1
    )
  }, [building, date, desk, exit_time, entry_time, floor, site, workplaceReason])

  const getMinTime = useCallback(() => {
    if (!date) return
    const _date = formatDate(date)
    const _today = formatDate(today)
    if (_date === _today) return today
  }, [date])

  const defaultMenuItem = () => {
    return (
      <MenuItem value="-1" disabled>
        <em>{t('select')}</em>
      </MenuItem>
    )
  }

  const onChangeTimeWorkplace = () => {
    if (!date) return
    setOpenTimePicker(true)
  }

  const clearWorkplaceReservation = () => {
    setEntryTime(EIGHT_AM)
    setExitTime(FIVE_PM)
    setSite(-1)
    setBuilding(-1)
    setFloor(-1)
    setDesk(DEFAULT_MARKER)
  }

  const clearRoomReservation = () => {
    setEntryTime(EIGHT_AM)
    setExitTime(FIVE_PM)
    setSite(-1)
    setBuilding(-1)
    setFloor(-1)
    setDesk(DEFAULT_MARKER)
    setWorkplaceReason(-1)
  }

  const handleBack = () => {
    if (activeStep - 1 === 0) {
      if (selectedType === 'desk') clearWorkplaceReservation()
      else clearRoomReservation()
      setSelectedType('desk')
    }
    setActiveStep(activeStep => activeStep - 1)
  }

  const onContinueTimePicker = useCallback(() => {
    setAllTime(allTimeValue)
    setEntryTime(timePickerValue)
    setExitTime(exitTimePickerValue)
    setSite(-1)
    setBuilding(-1)
    setFloor(-1)
    setDesk(DEFAULT_MARKER)
    setWorkplaceReason(-1)
    setOpenTimePicker(false)
  }, [allTimeValue, timePickerValue, exitTimePickerValue, DEFAULT_MARKER])

  const onCloseTimePicker = useCallback(() => {
    setOpenTimePicker(false)
    setAllTimeValue(false)
    setTimePickerValue(entry_time)
    setExitTimePickerValue(exit_time)
  }, [entry_time, exit_time])

  const getFormateDate = useCallback(() => {
    if (date) return formatDateToMed(date)
    return t('select')
  }, [date, t])

  const getFormateTimeWorkplace = useCallback(() => {
    if (allTime) return t('all day')
    if (entry_time && exit_time) {
      return `${formatTimeFromJS(entry_time)} - ${formatTimeFromJS(exit_time)}`
    }
    return t('select')
  }, [allTime, entry_time, exit_time, t])

  const horizontalSteeper = useCallback(() => {
    return (
      <Grid container spacing={3}>
        <Grid item xs>
          {(isLarge && activeStep === 1) && (
            <Button
              color="inherit"
              onClick={handleBack}
              startIcon={
                <ArrowBackIcon className="back-steeper" />
              }
            >
              {t('back')}
            </Button>
          )}
        </Grid>
        <Grid item>
          <Stepper activeStep={activeStep}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel></StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs>
        </Grid>
      </Grid>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, isLarge])

  const radioControlProps = useCallback(item => ({
    onChange: event => setSelectedType(event.target.value),
    checked: selectedType === item,
    value: item,
    name: 'radio-type',
    className: 'radio-type',
    inputProps: { 'aria-label': item },
  }), [selectedType])

  const evalContinue = useCallback(() => {
    if (selectedType === 'desk') {
      if (workplaceTotalReservations >= workplaceMaxBookingPerPerson) {
        setOpenMaxReservation(true)
      } else setActiveStep(1)
    }

    else if (selectedType === 'room') {
      if (workplaceTotalReservations >= workplaceMaxBookingPerPerson) {
        setOpenMaxReservation(true)
      } else setActiveStep(1)
    }
  }, [selectedType, workplaceMaxBookingPerPerson, workplaceTotalReservations])


  const slide0 = useCallback(() => {
    return (
      <div className="slide-0-wrapper">
        <h2 className="title-type">{t('what would you like to reserve?')}</h2>
        <MenuList>
          {enabled_workplace &&
            <MenuItem onClick={() => setSelectedType('desk')}>
              <ListItemIcon>
                <AirlineSeatReclineNormalIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('workplace')}</ListItemText>
              <Radio {...radioControlProps('desk')} />
            </MenuItem>}
          {enabled_workplace && <Divider />}
          {enabled_workplace &&
            <MenuItem onClick={() => setSelectedType('room')}>
              <ListItemIcon>
                <MeetingRoomRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('rooms')}</ListItemText>
              <Radio {...radioControlProps('room')} />
            </MenuItem>}
          {enabled_workplace && <Divider />}
        </MenuList>
        <Button
          className="big-button m-auto"
          size="large"
          variant="contained"
          disabled={selectedType === ''}
          onClick={evalContinue} >
          {t('continue')}
        </Button>
      </div>
    )
  }, [enabled_workplace, evalContinue, radioControlProps, selectedType, t])

  const onChangeSite = event => {
    const site = event.target.value
    setSite(site)
    setBuilding(-1)
    setFloor(-1)
    setDesk(DEFAULT_MARKER)
    setSelectedMarker(DEFAULT_MARKER)
    fetchBuilding(site)
  }

  const onChangeBuilding = event => {
    const building = event.target.value
    setBuilding(building)
    setFloor(-1)
    setDesk(DEFAULT_MARKER)
    setSelectedMarker(DEFAULT_MARKER)
    fetchFloors(building)
  }

  const onChangeFloor = event => {
    const floor = event.target.value
    setFloor(floor)
    setDesk(DEFAULT_MARKER)
    setSelectedMarker(DEFAULT_MARKER)
    fetchFloorMap(floors.find(f => f.id === floor)?.floor_map.id || 0)
  }

  const onChangeWorkplaceReason = event => {
    const workplaceReason = event.target.value
    setWorkplaceReason(workplaceReason)
  }

  const onChangeDesk = () => {
    if (floor === -1) return
    setOpenMap(true)
  }

  const EntryTimeIsLaterThanExitTime = useCallback(
    () => {
      const entry = formatDateToTimeISO(timePickerValue)
      const exit = formatDateToTimeISO(exitTimePickerValue)
      return (entry > exit)
    },
    [exitTimePickerValue, timePickerValue]
  )

  const disabledTimePickerWorplace = useCallback(
    () => EntryTimeIsLaterThanExitTime(),
    [EntryTimeIsLaterThanExitTime]
  )

  const renderTimePickerMessageWorkplace = useCallback(() => {
    if (EntryTimeIsLaterThanExitTime()) {
      return (
        <p className="message danger">
          {t('Check out time must be greater than check in time')}
        </p>
      )
    }
    return <p className='message'></p>
  }, [EntryTimeIsLaterThanExitTime, t])


  const slide1Workplace = () => {
    return (
      <>
        <div className="div-reservation">
          <h2>
            {
              selectedType === 'desk' ?
                t('workplace reservation') :
                t('rooms reservation')
            }
          </h2>
          <div className='form-wrapper workplace'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={isLarge ? 8 : 5} alignItems="flex-end">
                <Grid item xs={12} md={7}>
                  <label
                    className="pre-label-form">
                    {t('when do you plan to use the space?')}
                  </label>
                  <Grid
                    container
                    spacing={isLarge ? 0 : 5}
                    className="form-input-group"
                  >
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <InputLabel shrink>
                          {t('date')}
                        </InputLabel>
                        <TextField
                          value={getFormateDate()}
                          variant="filled"
                          fullWidth
                          inputRef={inputDateRef}
                        />
                      </FormControl>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <InputLabel shrink>
                          {t('hour')}
                        </InputLabel>
                        <TextField
                          value={getFormateTimeWorkplace()}
                          onClick={onChangeTimeWorkplace}
                          disabled={!date}
                          variant="filled"
                          fullWidth
                          inputRef={inputHourRef}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <label
                    className="pre-label-form">
                    {t('where do you want to sit?')}
                  </label>
                  <Grid
                    container
                    spacing={isLarge ? 0 : 5}
                    className="form-input-group"
                  >
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <InputLabel shrink>
                          {t('site')}
                        </InputLabel>
                        <Select
                          ref={selectSiteRef}
                          disabled={!date}
                          variant="filled"
                          labelId="site-label"
                          value={site}
                          onChange={onChangeSite}
                        >
                          {defaultMenuItem()}
                          {sites.map(s =>
                            <MenuItem
                              key={s.id}
                              value={s.id}>
                              {s.name}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <InputLabel shrink>
                          {t('building')}
                        </InputLabel>
                        <Select
                          disabled={site === -1}
                          variant="filled"
                          labelId="building-label"
                          value={building}
                          onChange={onChangeBuilding}
                        >
                          {defaultMenuItem()}
                          {buildings.map(b =>
                            <MenuItem
                              key={b.id}
                              value={b.id}>
                              {b.name}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <InputLabel shrink>
                          {t('floor')}
                        </InputLabel>
                        <Select
                          disabled={building === -1}
                          variant="filled"
                          labelId="floor-label"
                          value={floor}
                          onChange={onChangeFloor}
                        >
                          {defaultMenuItem()}
                          {floors.map(f =>
                            <MenuItem
                              key={f.id}
                              value={f.id}>
                              {f.name}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <InputLabel shrink>
                          {t('desk')}
                        </InputLabel>
                        <TextField
                          disabled={floor === -1}
                          value={desk.name}
                          onClick={onChangeDesk}
                          placeholder={t('select')}
                          variant="filled"
                          autoComplete="off"
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={7}>
                  <label
                    className="pre-label-form">
                    {t('what is your reason for attendance?')}
                  </label>
                  <Grid
                    container
                    spacing={isLarge ? 0 : 5}
                    className="form-input-group"
                  >
                    <Grid item xs={12} md>
                      <FormControl fullWidth>
                        <InputLabel shrink htmlFor="reason-label">
                          {t('reason')}
                        </InputLabel>
                        <Select
                          disabled={!desk}
                          variant="filled"
                          labelId="reason-label"
                          value={workplaceReason}
                          onChange={onChangeWorkplaceReason}
                        >
                          {defaultMenuItem()}
                          {workplaceReasons.map((r, index) =>
                            <MenuItem
                              key={index}
                              value={index}>
                              {reasonI18n(index)}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </div>
          <div className='new-reservation-footer'>
            <Button
              fullWidth={!isLarge}
              variant="outlined"
              disabled={!isEnabledWorkplace()}
              onClick={e =>
                selectedType === 'desk' ?
                  saveReservationWorkplace() :
                  saveReservationRoom()
              } >
              {isLarge ?
                t('ACCEPT RESERVATION') :
                t('ACCEPT')
              }
            </Button>
          </div>
        </div>
        <SwipeableTimePicker
          type="range"
          allTime={allTimeValue}
          time={timePickerValue}
          exitTime={exitTimePickerValue}
          open={openTimePicker}
          setAllTime={setAllTimeValue}
          setTime={setTimePickerValue}
          setExitTime={setExitTimePickerValue}
          minTime={getMinTime()}
          setOpen={setOpenTimePicker}
          onContinue={onContinueTimePicker}
          onClose={onCloseTimePicker}
          isDisabled={disabledTimePickerWorplace()}
          renderMessage={renderTimePickerMessageWorkplace}
        />
      </>
    )
  }

  const deskSuccessList = () => {
    return (
      <ul className="detail-list">
        <li>{t('workplace')}</li>
        <li><EventIcon sx={iconSx} /><span>{formatDateISOShort(successItem.reservation_date)}</span> </li>
        <li><LocationCityIcon sx={iconSx} /><span>{getPlaceByID(successItem)}</span> </li>
        <li><AirlineSeatReclineNormalIcon sx={iconSx} /><span>{successItem?.desk?.name}</span> </li>
        <li><WatchLaterIcon sx={iconSx} /><span>{getTime(successItem, t, true)}</span> </li>
      </ul>
    )
  }

  const roomSuccessList = () => {
    return (
      <ul className="detail-list">
        <li> {t('rooms')}</li>
        <li><EventIcon sx={iconSx} /><span>{formatDateISOShort(successItem.reservation_date)}</span> </li>
        <li><LocationCityIcon sx={iconSx} /><span>{getPlaceByID(successItem)}</span> </li>
        <li><AirlineSeatReclineNormalIcon sx={iconSx} /><span>{successItem?.room?.name}</span> </li>
        <li><WatchLaterIcon sx={iconSx} /><span>{getTime(successItem, t, true)}</span> </li>
      </ul>
    )
  }

  const slide2 = () => {
    return (
      <div className='Success-reservation'>
        <div className='success-box'>
          <CheckCircleIcon className="success-icon" />
          <h1 className='success-title'>{t('success reservation')}</h1>
          <div className="detail-list-wrapper">
            {
              selectedType === 'desk' ?
                deskSuccessList() :
                roomSuccessList()
            }
          </div>
          <Stack spacing={2} className='success-footer'>
            <Button
              variant="outlined"
              onClick={() => navigate("/reservations")} >
              {t('see my reservations')}
            </Button>
          </Stack>
        </div>
      </div>
    )
  }

  const getStep = () => {
    if (activeStep === 0) return slide0()
    if (activeStep === 1) return slide1Workplace()
    if (activeStep === 2) return slide2()
    return ''
  }

  const onConfirmMarker = useCallback(() => {
    setDesk(selectedMarker)
    setOpenMap(false)

  }, [selectedMarker])

  const onCancelMarker = useCallback(() => {
    const _markers = [...markers]

    _markers.forEach(m => {
      if (m.id === desk.id) {
        m.selected = true
      } else m.selected = false
    })

    setOpenMap(false)
    setMarkers(_markers)
    setSelectedMarker(desk)
  }, [desk, markers])

  const onClickMarker = useCallback((e, marker) => {
    const _markers = [...markers]
    if (marker.occupied) return

    _markers.forEach(m => {
      if (m.id === marker.id)
        m.selected = true
      else m.selected = false
    })

    setMarkers(_markers)
    setSelectedMarker(marker)

  }, [markers])

  const getLegend = useCallback(() => {
    return (
      <Stack
        justifyContent={isLarge ? 'flex-start' : 'center'}
        direction="row"
        spacing={4}
        className="legend"
      >
        <label><DeskDot width="12" color="#5F75B9" /> {t('available')}</label>
        <label><DeskDot width="12" color="#8C8CA1" /> {t('busy')}</label>
        <label><CheckCircleIcon sx={iconSelected} /> {t('selected')}</label>
      </Stack>
    )
  }, [isLarge, t])

  const detailMap = useCallback(() => {
    return (
      <Dialog
        className="dialog-detail detail-map"
        fullScreen={!isLarge}
        fullWidth={true}
        maxWidth="lg"
        open={openMap}
        onClose={onCancelMarker}
        aria-labelledby="responsive-dialog-title">
        <DialogContent className="dialog-detail-content">
          {isLarge && getLegend()}
          {!isLarge &&
            <div className="detail-map-header">
              <h1>{t('selected desk')}</h1>
              <h2>{selectedMarker.name}</h2>
              <IconButton
                className="close-button"
                aria-label="close"
                onClick={onCancelMarker} >
                <CloseIcon />
              </IconButton>
            </div>
          }
          <FloorMap
            floorImage={floorImage}
            markers={markers}
            onClickMarker={onClickMarker}
          />
          {!isLarge && getLegend()}
          <div className='detail-map-footer'>
            <Button
              fullWidth={!isLarge}
              variant="outlined"
              disabled={selectedMarker.id === -1}
              onClick={onConfirmMarker} >
              {t('confirm')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }, [floorImage, getLegend, isLarge, markers, onCancelMarker, onClickMarker, onConfirmMarker, openMap, selectedMarker.id, selectedMarker.name, t])

  const doYouWantToEditModal = useCallback(() => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={doYouWantToEdit}
        onClose={() => setDoYouWantToEdit(false)}
        aria-labelledby="responsive-dialog-title">
        <DialogContent className="dialog-info-content">
          <div className="detail-header">
            <InfoOutlinedIcon />
            {t('do you want to edit this field?')}
          </div>
          <Divider className="detail-hr" />
          <p className='enabled'>{t('editing this field will require you to select all subsequent details again')}</p>
          <Stack spacing={2} direction="row" className='detail-footer'>
            <Button
              variant="outlined"
              onClick={() => setDoYouWantToEdit(false)} >
              {t('go back')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }, [doYouWantToEdit, t])

  const maxReservationModal = useCallback(() => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openMaxReservation}
        onClose={() => setOpenMaxReservation(false)}
        aria-labelledby="responsive-dialog-title">
        <DialogContent className="dialog-info-content">
          <div className="detail-header">
            <InfoOutlinedIcon />
            {t('maximum reserves reached')}
          </div>
          <Divider className="detail-hr" />
          <p className='enabled'>{t('you can only have x active workstation reservations and you have reached that maximum', { x: workplaceMaxBookingPerPerson })}</p>
          <Stack spacing={2} direction="row" className='detail-footer'>
            <Button
              variant="outlined"
              onClick={() => setOpenMaxReservation(false)} >
              {t('go back')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }, [openMaxReservation, t, workplaceMaxBookingPerPerson])

  const goField = field => {
    if (field === 'date') inputDateRef.current.focus()
    if (field === 'hour') inputHourRef.current.focus()
    if (field === 'site') selectSiteRef.current.focus()
    setMaximumOccupancyFloor(false)
  }

  const maximumOccupancyFloorModal = useCallback(() => {
    return (
      <Dialog
        className='image-information-dialog'
        fullWidth={true}
        maxWidth="sm"
        open={maximumOccupancyFloor}
        onClose={() => setMaximumOccupancyFloor(false)}
        aria-labelledby="responsive-dialog-title">
        <DialogContent className="dialog-info-content">
          <img src="calendar_reservation.svg" alt="calendar reservation" />
          <div className="detail-header">{t('sorry')}</div>
          <p className='enabled'>{t('all spaces are occupied on this floor for the selected date and time')}</p>
          <Stack spacing={2} direction="column" className='detail-footer'>
            <Button
              startIcon={<EventIcon />}
              variant="outlined"
              onClick={() => goField('date')} >
              {t('change date')}
            </Button>
            <Button
              startIcon={<AccessTimeIcon />}
              variant="outlined"
              onClick={() => goField('hour')} >
              {t('change schedule')}
            </Button>
            <Button
              startIcon={<PlaceOutlinedIcon />}
              variant="outlined"
              onClick={() => goField('site')} >
              {t('change place')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }, [maximumOccupancyFloor, t])


  return (
    <div className='New-reservation'>
      <Layout2
        title={t('new reservation')}
        goBack={() => navigate("/reservations")}>
        <div className="new-reservation">
          {horizontalSteeper()}
          {getStep()}
        </div>
      </Layout2>
      {detailMap()}
      {maxReservationModal()}
      {doYouWantToEditModal()}
      {maximumOccupancyFloorModal()}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    enabled_parking: state.profile.enabled_parking,
    enabled_workplace: state.profile.enabled_workplace,
    maxFutureDaysToReserve: state.profile.company.max_future_days_to_reserve,
    workplaceTotalReservations: state.workplace.total,
    workplaceMaxFutureDaysToReserve: state.profile.company.workplace_max_future_days_to_reserve,
    workplaceMaxBookingPerPerson: state.profile.company.workplace_max_booking_per_person,
    parkingSchedules: state.profile.company.parking_schedules,
    enabledParkingSchedule: state.profile.enabled_parking_schedule
  }
}

export default connect(mapStateToProps)(NewReservations)