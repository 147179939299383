import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import Layout from '../../common/components/Layout/Layout'
import FloorMap from '../../common/components/FloorMap/FloorMap'
import { getPlace, stopEvent, getTime } from '../../common/utilities/utilities'
import { api, popTokens } from '../../api/api'

import {
  formatDateISOShort,
  removeTimezoneFromISO,
  formatDateToISOShort
} from '../../common/utilities/FormatDate'

import {
  Stack,
  Card,
  Button,
  Divider,
  Dialog,
  DialogContent,
} from '@mui/material'

import {
  Add as AddIcon,
  Event as EventIcon,
  WatchLater as WatchLaterIcon,
  LocationCity as LocationCityIcon,
  InfoOutlined as InfoOutlinedIcon,
  AirlineSeatReclineNormal as AirlineSeatReclineNormalIcon,
} from '@mui/icons-material'

import {
  MAX_401_ATTEMPTS,
  CENTER as DEFAULT_CENTER,
  INITIAL_ITEM
} from '../../variables'

import {
  setLog,
  types as errorTypes
} from '../../common/utilities/LogError'

import { Types } from '../../state/actionTypes'

import './Reservations.sass'

const initialFloorImage = {
  url: '',
  size: []
}

const today = new Date()
const iconSx = { color: '#8C8CA1', fontSize: 15 }

let iteration = 0

function Reservations({ dispatch, firstName, lastName }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [markers, setMarkers] = useState([])
  const [roomReservations, setRoomReservations] = useState([])
  const [deskReservations, setDeskReservations] = useState([])
  const [floorImage, setFloorImage] = useState(initialFloorImage)
  const [remove, setRemove] = useState(false)
  const [item, setItem] = useState(INITIAL_ITEM)
  const [selectedType, setSelectedType] = useState('desk')

  const setLoading = useCallback(loading => {
    dispatch({
      type: Types.SET_BACKDROP_LOADING,
      payload: { loading }
    })
  }, [dispatch])

  const logout = useCallback(() => {
    dispatch({ type: Types.CLEAN_DEVISE_AUTH })
    navigate('/login')
  }, [dispatch, navigate])

  const cleanReservation = (reservations = []) => {
    let _reservations = []
    const todayISO = formatDateToISOShort(today)
    reservations.forEach(r => {
      if (
        r.reservation_date !== todayISO ||
        r.status === 'RELEASED'
      ) return

      _reservations.push({
        ...r,
        entry_time: removeTimezoneFromISO(r.entry_time),
        exit_time: removeTimezoneFromISO(r.exit_time)
      })
    })

    return _reservations
  }

  const eval401Error = useCallback((error, call) => {
    iteration++
    if (iteration < MAX_401_ATTEMPTS) {
      popTokens()
      call()
    } else {
      setLog({ type: errorTypes.MAX_401_ATTEMPTS })
      logout(error)
    }
  }, [logout])

  const defaultSuccess = useCallback(r => {
    iteration = 0
    setLoading(false)
    return r
  }, [setLoading])

  const defaultCatch = useCallback((error, currentFunction) => {
    setLoading(false)
    if (error?.response?.status === 401) eval401Error(error, currentFunction)
    else if (error.response) {
      setLog({
        title: error.response.status,
        type: errorTypes.API_ERROR,
        message: error.response.data.errors,
        api_url: error.response.config.url,
        api_method: error.response.config.method,
        api_params: error.response.config.data
      })
    } else setLog({ type: errorTypes.API_ERROR, message: error.message })
  }, [eval401Error, setLoading])

  const fetchRoomReservations = useCallback(() => {
    setLoading(true)
    api.get('/workplace/room_reservations')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        setRoomReservations(cleanReservation(data.room_reservations))
      })
      .catch(error => defaultCatch(error, fetchRoomReservations))
  }, [defaultCatch, defaultSuccess, setLoading])

  const fetchDeskReservations = useCallback(() => {
    setLoading(true)
    api.get('/workplace/desk_reservations')
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        setDeskReservations(cleanReservation(data.desk_reservations))
        fetchRoomReservations()
      })
      .catch(error => defaultCatch(error, fetchDeskReservations))
  }, [defaultCatch, defaultSuccess, fetchRoomReservations, setLoading])

  useEffect(() => {
    fetchDeskReservations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchFloorsImage = useCallback(params => {
    const { id, width, height } = params
    setLoading(true)
    api.get(`/workplace/floor_map/serve/${id}`, { responseType: "blob" })
      .then(defaultSuccess)
      .then(({ data }) => {
        const blob = URL.createObjectURL(data)
        setFloorImage({ url: blob, size: [width, height] })
      })
      .catch(error => defaultCatch(error, () => fetchFloorsImage(params)))
  }, [defaultCatch, defaultSuccess, setLoading])

  const saveMarkArrival = useCallback(id => {
    setLoading(true)

    const payload = {
      id,
      desk_session: { id }
    }

    api.post('/workplace/desk_session', payload)
      .then(defaultSuccess)
      .then(() => {
        dispatch({
          type: Types.SET_SNACKBAR_DATA,
          payload: {
            open: true,
            message: t('Your arrival was notified correctly'),
            severity: 'success',
            autoHideDuration: 6000
          }
        })
        fetchDeskReservations()
      })
      .catch(error => defaultCatch(error, () => saveMarkArrival(id)))
  }, [defaultCatch, defaultSuccess, dispatch, fetchDeskReservations, setLoading, t])


  const markArrival = useCallback((e, { id }) => {
    stopEvent(e)
    saveMarkArrival(id)
  }, [saveMarkArrival])

  const setFloorMapDesk = useCallback((data, item) => {
    const { desks } = data.floor_map
    if (item) {
      const marker = desks.find(m => m.id === item.desk.id)
      if (marker) marker.selection = true
    }
    setMarkers(desks)
  }, [])

  const setFloorMapRoom = useCallback((data, item) => {
    const { rooms } = data.floor_map
    if (item) {
      const marker = rooms.find(m => m.id === item.room.id)
      if (marker) marker.selection = true
    }
    setMarkers(rooms)
  }, [])

  const fetchFloorMap = useCallback((item, type) => {
    setLoading(true)
    api.get(`/workplace/floor_map/${item.floor_map.id}`)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        if (type === 'desk') setFloorMapDesk(data, item)
        else setFloorMapRoom(data, item)
        fetchFloorsImage(data.floor_map)
      })
      .catch(error => defaultCatch(error, () => fetchFloorMap(item, type)))
  }, [defaultCatch, defaultSuccess, fetchFloorsImage, setFloorMapDesk, setFloorMapRoom, setLoading])

  const onClickReservation = useCallback((item, type) => {
    setSelectedType(type)
    setItem(item, type)
    fetchFloorMap(item, type)
  }, [fetchFloorMap])

  const cancelReservation = useCallback((e, item, type) => {
    stopEvent(e)
    setSelectedType(type)
    setItem(item)
    setRemove(true)
  }, [])

  const isDisabledMarkArrival = useCallback(item => {
    return !!item?.session?.start_time
  }, [])

  const getDeskItem = useCallback(item => {
    return (
      <Card className='reservation' onClick={() => onClickReservation(item, 'desk')}>
        <label>{formatDateISOShort(item.reservation_date)}</label>
        <h3>{t('workplace')}</h3>
        <Divider />
        <ul>
          <li><AirlineSeatReclineNormalIcon sx={iconSx} />{item.desk.name}</li>
          <li><WatchLaterIcon sx={iconSx} />{getTime(item, t)}</li>
        </ul>
        <Stack direction="column" spacing={2}>
          <Button
            disabled={isDisabledMarkArrival(item)}
            variant="contained"
            onClick={e => markArrival(e, item)}
          >
            {t('mark arrival')}
          </Button>
          <Button
            variant="text"
            color="error"
            onClick={e => cancelReservation(e, item, 'desk')}>
            {t('cancel reservation')}
          </Button>
        </Stack>
      </Card>
    )
  }, [cancelReservation, isDisabledMarkArrival, markArrival, onClickReservation, t])

  const getRoomItem = useCallback(item => {
    return (
      <Card className='reservation' onClick={() => onClickReservation(item, 'room')}>
        <label>{formatDateISOShort(item.reservation_date)}</label>
        <h3>{t('rooms')}</h3>
        <Divider />
        <ul>
          <li><AirlineSeatReclineNormalIcon sx={iconSx} />{item.room.name}</li>
          <li><WatchLaterIcon sx={iconSx} />{getTime(item, t)}</li>
        </ul>
        <Stack direction="column" spacing={2}>
          {/* <Button
            variant="contained"
            onClick={e => stopEvent(e)}>
            {t('mark arrival')}
          </Button> */}
          <Button
            variant="text"
            color="error"
            onClick={e => cancelReservation(e, item, 'room')}>
            {t('cancel reservation')}
          </Button>
        </Stack>
      </Card>
    )
  }, [cancelReservation, onClickReservation, t])

  const removeDialogListDesk = useCallback(() => {
    return (
      <ul className="detail-list">
        <li>{t('workplace')}</li>
        <li><EventIcon sx={iconSx} /><span>{formatDateISOShort(item.reservation_date)}</span> </li>
        <li><LocationCityIcon sx={iconSx} /><span>{getPlace(item)}</span> </li>
        <li><AirlineSeatReclineNormalIcon sx={iconSx} /><span>{item?.desk?.name}</span> </li>
        <li><WatchLaterIcon sx={iconSx} /><span>{getTime(item, t)}</span> </li>
      </ul>
    )
  }, [item, t])

  const removeDialogListRoom = useCallback(() => {
    return (
      <ul className="detail-list">
        <li>{t('rooms')}</li>
        <li><EventIcon sx={iconSx} /><span>{formatDateISOShort(item.reservation_date)}</span> </li>
        <li><LocationCityIcon sx={iconSx} /><span>{getPlace(item)}</span> </li>
        <li><AirlineSeatReclineNormalIcon sx={iconSx} /><span>{item?.room?.name}</span> </li>
        <li><WatchLaterIcon sx={iconSx} /><span>{getTime(item, t)}</span> </li>
      </ul>
    )
  }, [item, t])

  const onCancelReservation = useCallback(() => {
    setRemove(false)
    setLoading(true)

    let url = ''

    if (selectedType === 'desk') url = 'workplace/desk_reservation'
    else url = 'workplace/room_reservation'

    api.delete(`/${url}/${item.id}`)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        setItem(INITIAL_ITEM)
        setFloorImage(initialFloorImage)
        fetchDeskReservations()
      })
      .catch(error => defaultCatch(error, onCancelReservation))
  }, [defaultCatch, defaultSuccess, fetchDeskReservations, item.id, selectedType, setLoading])

  const onCloseRemoveDialog = () => {
    setRemove(false)
    setFloorImage(initialFloorImage)
  }

  const removeDialog = useCallback(() => {
    return (
      <Dialog
        fullScreen
        maxWidth="sm"
        open={remove}
        onClose={onCloseRemoveDialog}
        aria-labelledby="responsive-dialog-title">
        <div className="border-bar" />
        <DialogContent className="dialog-info-content">
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            className="detail-header">
            <InfoOutlinedIcon />
            {t('cancel reservation')}
          </Stack>
          <p>{t('cancel reservation paragraph')}</p>
          <div className="detail-remove-info">
            {
              selectedType === 'desk' ?
                removeDialogListDesk() :
                removeDialogListRoom()
            }
          </div>
          <Stack spacing={2} direction="row" className='detail-footer'>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setRemove(false)} >
              {t('go back')}
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="error"
              onClick={onCancelReservation} >
              {t('cancel reservation')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }, [onCancelReservation, remove, removeDialogListDesk, removeDialogListRoom, selectedType, t])

  const getCenterItem = useCallback(() => {
    if (!item) return DEFAULT_CENTER
    const marker = markers.find(m => m.selection)
    return marker ? [marker.x, marker.y] : DEFAULT_CENTER
  }, [item, markers])

  const getFloorMap = useCallback(r => {
    if (floorImage.url) return (
      <FloorMap
        className="detail-list-map"
        center={getCenterItem()}
        floorImage={floorImage}
        markers={markers}
        doubleClickZoom={false}
      />
    )

    return (
      <div className='default-map-text absolute-center'>
        Selecciona una reserva para ver el mapa
      </div>
    )
  }, [floorImage, getCenterItem, markers])

  return (
    <div className="Reservations">
      <Layout>
        <div className='sub-header'>
          <h1>{t('Hi')} {firstName} {lastName}</h1>
          <p>{t('Here are your reservations for today')}</p>
        </div>
        <Stack
          direction="row"
          spacing={1}
        >
          <Stack
            spacing={2}
            className='reservation-wrapper'
          >
            {deskReservations.map((r, index) => <div key={index}>{getDeskItem(r)}</div>)}
            {roomReservations.map((r, index) => <div key={index}>{getRoomItem(r)}</div>)}
            <Card
              className='reservation new'
              onClick={() => navigate("/new-reservation")}
            >
              <Stack
                direction="column"
                alignItems="center"
                spacing={1}
              >
                <AddIcon />
                <label>
                  {t('new reservation')}
                </label>
              </Stack>
            </Card>
          </Stack>
          <div>
            {item.type !== 'ParkingReservation' && <p className="place-wrapper">{getPlace(item)}</p>}
            <Card className={`map-wrapper ${item.type === 'ParkingReservation' ? 'full' : ''}`}>
              {getFloorMap()}
            </Card>
          </div>
        </Stack>
      </Layout>
      {removeDialog()}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    firstName: state.profile.selected_user.first_name,
    lastName: state.profile.selected_user.last_name
  }
}

export default connect(mapStateToProps)(Reservations)