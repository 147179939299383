import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import thunk from 'redux-thunk'

import profile from './reducers/profile'
import snackbar from './reducers/snackbar'
import backdrop from './reducers/backdrop'
import utilities from './reducers/utilities'
import parking from './reducers/parking'
import workplace from './reducers/workplace'
import modals from './reducers/modals'

const rootReducer = combineReducers({
  profile,
  snackbar,
  backdrop,
  utilities,
  parking,
  workplace,
  modals,
})

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['profile']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const configureStore = () => {
  let store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))
  let persistor = persistStore(store)
  return { store, persistor }
};

export default configureStore