import {
  formatTime,
  removeTimezoneFromISO,
  isAllDay
} from './FormatDate'

import {
  styled,
  InputBase,
  Box,
} from '@mui/material'

import NoRows from '../components/Svgs/NoRows'
import { REGEX_HTTP_URL } from '../../variables'

const getCurrentBrowser = () => {
  var ua = navigator.userAgent
  var tem
  var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return 'IE ' + (tem[1] || '')
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera')
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
  return M.join(' ');
}

const getPlace = item => {
  const {
    site = { name: '' },
    building = { name: '' },
    floor = { name: '' }
  } = item
  return `${site.name}, ${building.name}, ${floor.name}`
}

const getTime = (item, t, removeTimeZone = false) => {
  if (isAllDay(item)) return t('all day')

  const {
    entry_time = '',
    exit_time = ''
  } = item

  let _entry_time = ''
  let _exit_time = ''

  if (removeTimeZone) {
    _entry_time = formatTime(removeTimezoneFromISO(entry_time))
    _exit_time = formatTime(removeTimezoneFromISO(exit_time))
  } else {
    _entry_time = formatTime(entry_time)
    _exit_time = formatTime(exit_time)
  }

  return `${_entry_time} - ${_exit_time}`
}

const sortItems = (items = []) => {
  return items.sort((a, b) => {
    const _a = a.name.toLowerCase()
    const _b = b.name.toLowerCase()

    if (_a < _b) return -1
    else if (_a > _b) return 1
    return 0
  })
}

const getFullNameGuest = params => {
  const {
    first_name,
    last_name_one
  } = params

  return `${first_name} ${last_name_one}`
}

const getNoRowOverlay = (t) => {
  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }))

  return (
    <StyledGridOverlay>
      <NoRows />
      <Box sx={{ mt: 1 }}>{t('No Rows')}</Box>
    </StyledGridOverlay>
  )
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#2b2b2b',
    border: '1px solid #D1D1D1',
    fontSize: 16,
    width: '350px',
    height: '10px',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ])
  },
  '& .MuiInputBase-input.Mui-disabled': {
    backgroundColor: '#F0F0F0'
  }
}));

const debounce = (func, wait, immediate) => {
  var timeout
  return function () {
    var context = this, args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    };
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

const stopEvent = e => {
  if (e.preventDefault !== undefined) e.preventDefault()
  if (e.stopPropagation !== undefined) e.stopPropagation()
}

const getNumberToWeekDay = (n, t) => {
  if (n === 0) return t('Sunday')
  if (n === 1) return t('Monday')
  if (n === 2) return t('Tuesday')
  if (n === 3) return t('Wednesday')
  if (n === 4) return t('Thursday')
  if (n === 5) return t('Friday')
  if (n === 6) return t('Saturday')
  return ''
}

const parseMessageHtml = message => {
  const regex = REGEX_HTTP_URL;
  return message.replace(regex, (match) => {
    return `<a href="${match}" target="_blank">${match}</a>`;
  });
}


export {
  parseMessageHtml,
  getNumberToWeekDay,
  getCurrentBrowser,
  getFullNameGuest,
  getNoRowOverlay,
  BootstrapInput,
  stopEvent,
  sortItems,
  debounce,
  getPlace,
  getTime,
}