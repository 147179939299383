import { Types } from '../actionTypes'

const initialState = {
  total: 0,
  reasons: [],
  occupancy: {
    user_actives: 0,
    occupancy_percentage: 0
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_WORKPLACE_TOTAL_RESERVATIONS:
      const total = action.payload
      return { ...state, total }
    case Types.SET_WORKPLACE_REASONS:
      const reasons = action.payload
      return { ...state, reasons }
    case Types.SET_WORKPLACE_OCCUPANCY:
      const { occupancy } = action.payload
      return { ...state, occupancy }
    default:
      return state
  }
}

export default reducer;