import React, { useCallback, useState, useEffect } from 'react'
import { connect } from "react-redux"

import {
  MapContainer,
  SVGOverlay,
  Marker,
  Popup
} from 'react-leaflet'

import {
  Stack,
  IconButton
} from '@mui/material'

import {
  Close as CloseIcon,
  MeetingRoomRounded as MeetingRoomRoundedIcon
} from '@mui/icons-material'

import * as L from 'leaflet'
import './FloorMap.sass'

const INITIAL_OFFSET = 4
const INITIAL_SIZE = 6.5

const defaultFunction = () => { }


function FloorMap(props) {
  const {
    className = "",
    markers = [],
    floorImage,
    isHeatMap = false,
    doubleClickZoom = true,
    onClickMap = defaultFunction,
    onDoubleClickMap = defaultFunction,
    onClickMarker = defaultFunction
  } = props

  const [map, setMap] = useState(null)
  const [image, setImage] = useState(null)

  const loadImage = (floorImage, map) => {
    const { url, size } = floorImage
    const _size = [...size]
    const bounds = [[0, 0], _size.reverse()]

    if (image) map.removeLayer(image)
    setImage(L.imageOverlay(url, bounds, { opacity: 0.7 }).addTo(map))
    map.fitBounds(bounds)
  }

  const whenReady = ({ target: map }) => {
    map.on('click', onClickMap)
    map.on('dblclick', onDoubleClickMap)
    setMap(map)
    if (floorImage.url) loadImage(floorImage, map)
  }

  const getFill = heat => {
    if (heat < 0.2) return '#4986AB'
    if (heat < 0.4) return '#97CFA9'
    if (heat < 0.6) return '#F7BD41'
    if (heat < 0.8) return '#F18833'
    return '#B33B1B'
  }

  const getDeskMapIcon = m => {
    let iconUrl = 'blue_dot.svg'
    let shadowUrl = null

    if (m.selected) iconUrl = 'selected_dot.svg'
    else if (m.selection) {
      iconUrl = 'yellow_dot.svg'
      shadowUrl = 'yellow_dot_background.svg'
    }
    else if (m.occupied) iconUrl = 'gray_dot.svg'

    return L.icon({
      iconUrl,
      shadowUrl,
      popupAnchor: null,
      iconSize: [32, 32],
      iconAnchor: [16, 16],
      shadowSize: [64, 64],
      shadowAnchor: [32, 32],
    })
  }

  const getPosition = useCallback(m => {
    const imageHeight = floorImage.size[1]
    return [
      - m.y + imageHeight - INITIAL_OFFSET,
      m.x + INITIAL_OFFSET
    ]
  }, [floorImage.size])

  const markerHandlersEvent = useCallback(marker => {
    return {
      click: e => onClickMarker(e, marker)
    }
  }, [onClickMarker])

  const getMarkerPopup = useCallback(m => {
    if (!m.popup) return ''
    return (
      <Popup className="popup-marker">
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
        >
          <Stack
            direction="row"
            spacing={2}
          >
            <h2 className='header'>
              <MeetingRoomRoundedIcon />
              {m.name}
            </h2>
            <IconButton
              className="close-button"
              aria-label="close"
              onClick={() => map.closePopup()} >
              <CloseIcon />
            </IconButton>
          </Stack>
          {
            m.desk_photos &&
            m.desk_photos[0] &&
            <img
              src={m.desk_photos[0]}
              alt='office'
            />
          }
        </Stack>
      </Popup>
    )
  }, [map])

  const getBounds = useCallback(m => {
    let radius = m.radius === 0 ? 1 : m.radius
    radius = radius * INITIAL_SIZE
    const [y, x] = getPosition(m)
    return [
      [y - radius, x - radius],
      [y + radius, x + radius]
    ]
  }, [getPosition])

  const getHeatMarkers = useCallback(() => {
    return markers.map((m, idx) => {
      return (
        <SVGOverlay
          key={`marker-${idx}`}
          bounds={getBounds(m)}
          attributes={{ stroke: 'transparent' }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 142 141">
            <circle cx="71.23" cy="70.5" r="70.5" fill={getFill(m.heat)} fillOpacity=".1" transform="rotate(180 71.23 70.5)" />
            <circle cx="71.23" cy="70.5" r="53.95" fill={getFill(m.heat)} fillOpacity=".1" transform="rotate(180 71.23 70.5)" />
            <circle cx="71.23" cy="70.5" r="31.57" fill={getFill(m.heat)} fillOpacity=".1" transform="rotate(180 71.23 70.5)" />
            <ellipse cx="71.38" cy="70.5" fill={getFill(m.heat)} fillOpacity=".2" rx="14.87" ry="15.02" transform="rotate(180 71.38 70.5)" />
          </svg>
        </SVGOverlay>
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBounds])

  const getMarkers = useCallback(() => {
    return markers.map((m, idx) => {
      return (
        <Marker
          key={`marker-${idx}`}
          icon={getDeskMapIcon(m)}
          eventHandlers={markerHandlersEvent(m)}
          position={getPosition(m)}
        >
          {getMarkerPopup(m)}
        </Marker>
      )
    })
  }, [getMarkerPopup, getPosition, markerHandlersEvent, markers])

  const getItems = useCallback(() => {
    if (isHeatMap) return getHeatMarkers()
    return getMarkers()
  }, [getHeatMarkers, getMarkers, isHeatMap])

  useEffect(() => {
    if (!map) return
    if (!floorImage.url) return
    loadImage(floorImage, map)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorImage])


  return (
    <div className={`FloorMap ${className}`}>
      {floorImage &&
        <MapContainer
          zoom={0}
          minZoom={-5}
          center={[0, 0]}
          crs={L.CRS.Simple}
          attributionControl={false}
          doubleClickZoom={doubleClickZoom}
          whenReady={whenReady}>
          {getItems()}
        </MapContainer>
      }
    </div>
  )
}

export default connect(null)(FloorMap)
