import axios from 'axios'

import {
  setLog,
  types as errorTypes
} from '../common/utilities/LogError'

import { MAX_401_ATTEMPTS } from '../variables'


const DEFAULT_DOMAIN = 'public'
let iteration = 0

const clearDomain = domain => {
  const _domain = domain ||
    sessionStorage.getItem('domain') ||
    DEFAULT_DOMAIN

  if (
    _domain === 'undefined' ||
    _domain === DEFAULT_DOMAIN
  ) return ''
  return `${_domain}.`
}

const defaultFunction = () => { }

const getBaseUrl = domain => {
  return process.env.REACT_APP_STAGE === 'production' ?
    `https://${clearDomain(domain)}corporateparking.parso.cr/api` :
    `https://${clearDomain(domain)}corporatedev.parso.cr/api`
}

const api = axios.create()

const setTokenHeader = config => {
  let sessionTokens = sessionStorage.getItem('tokens') || '[]'
  sessionTokens = JSON.parse(sessionTokens)

  if (sessionTokens.length > 0) {
    const lastTokens = sessionTokens[sessionTokens.length - 1]
    config.headers['access-token'] = lastTokens['access-token']
    config.headers['token-type'] = lastTokens['token-type']
    config.headers['client'] = lastTokens['client']
    config.headers['uid'] = lastTokens['uid']
  }
}

api.interceptors.request.use(
  config => {
    setTokenHeader(config)
    config.baseURL = getBaseUrl()
    return config
  }
);

const doneHandler = response => {
  if (response?.headers['access-token']) {
    setTokens(response.headers)
  }

  return response
}

api.interceptors.response.use(doneHandler);


const setTokens = tokens => {
  let sessionTokens = sessionStorage.getItem('tokens') || '[]'
  sessionTokens = JSON.parse(sessionTokens)
  sessionTokens.push({
    'access-token': tokens['access-token'],
    'client': tokens['client'],
    'token-type': tokens['token-type'],
    'uid': tokens['uid']
  })
  sessionStorage.setItem('tokens', JSON.stringify(sessionTokens))
}

const setTokensForce = tokens => {
  sessionStorage.setItem('tokens', JSON.stringify(tokens))
}

const clearTokens = () => {
  sessionStorage.setItem('tokens', '[]')
  sessionStorage.setItem('domain', '')
}

const popTokens = () => {
  let sessionTokens = sessionStorage.getItem('tokens') || '[]'
  sessionTokens = JSON.parse(sessionTokens)
  if (sessionTokens.length <= 1) return
  sessionTokens.pop()
  sessionStorage.setItem('tokens', JSON.stringify(sessionTokens))
}

const getTokens = () => {
  let sessionTokens = sessionStorage.getItem('tokens') || '[]'
  sessionTokens = JSON.parse(sessionTokens)
  return sessionTokens
}

const isAuth = () => {
  return JSON.parse(sessionStorage.getItem('tokens') || '[]').length > 0
}

const eval401Error = (error, call, logout) => {
  const { success } = error.response.data
  if (!success) return


  iteration++
  if (iteration < MAX_401_ATTEMPTS) {
    popTokens()
    call()
  } else {
    setLog({
      title: error.response.status,
      type: errorTypes.MAX_401_ATTEMPTS,
      message: error.response.data.errors,
      api_url: error.response.config.url,
      api_method: error.response.config.method,
      api_params: error.response.config.data
    })
    logout(error)
  }
}

const getDefaultCatch = params => {
  const {
    setLoading = defaultFunction,
    logout = defaultFunction
  } = params

  return function (error, currentFunction) {
    setLoading(false)
    if (error?.response?.status === 401) eval401Error(error, currentFunction, logout)
    else if (error.response) {
      setLog({
        title: error.response.status,
        type: errorTypes.API_ERROR,
        message: error.response.data.errors,
        api_url: error.response.config.url,
        api_method: error.response.config.method,
        api_params: error.response.config.data
      })
    } else setLog({ type: errorTypes.API_ERROR, message: error.message })
  }

}

const getDefaultSuccess = params => {
  const {
    setLoading = defaultFunction
  } = params

  return function (response) {
    iteration = 0
    setLoading(false)
    return response
  }
}

export {
  api,
  isAuth,
  setTokens,
  popTokens,
  getTokens,
  getBaseUrl,
  clearTokens,
  setTokensForce,
  getDefaultCatch,
  getDefaultSuccess
}