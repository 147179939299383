import { t } from "i18next"

const getAllLangText = text => {
  return [
    t(text, { lng: 'es' }),
    t(text, { lng: 'en' })
  ]
}

const reasonI18n = code => {
  const _code = Number(code)
  if (_code === 0) return t('private meeting')
  if (_code === 1) return t('workday')
  if (_code === 2) return t('training')
  if (_code === 3) return t('special event')
  if (_code === 4) return t('others')
  return code
}

const reasonI18nReverse = reason => {
  if (getAllLangText('private meeting').includes(reason)) return 0
  if (getAllLangText('workday').includes(reason)) return 1
  if (getAllLangText('training').includes(reason)) return 2
  if (getAllLangText('special event').includes(reason)) return 3
  if (getAllLangText('others').includes(reason)) return 4
  return reason
}

const reasonWorkplaceI18n = code => {
  const _code = Number(code)
  if (_code === 0) return t('private meeting')
  if (_code === 1) return t('workday')
  if (_code === 2) return t('training')
  if (_code === 3) return t('special event')
  if (_code === 4) return t('others')
  return code
}

const reasonWorkplaceI18nReverse = reason => {
  if (getAllLangText('private meeting').includes(reason)) return 0
  if (getAllLangText('workday').includes(reason)) return 1
  if (getAllLangText('training').includes(reason)) return 2
  if (getAllLangText('special event').includes(reason)) return 3
  if (getAllLangText('others').includes(reason)) return 4
  return reason
}

const errorCreateReservationI18n = error => {
  if (error === 'Ya existe una reservación para este dia')
    return t('there is already a reservation for this day')

  if (error === 'La cantidad de espacios asignados al grupo se ha superado')
    return t('the number of slots allocated to the group has been exceeded')

  if (error === 'Entry time La hora de ingreso supera el mínimo de tiempo previo a reservar')
    return t('the check-in time exceeds the minimum time prior to booking')

  if (error === 'Entry time No se puede crear la reserva para tiempo superado')
    return t('cannot create reservation for time exceeded')

  return error
}

const errorCreateReservationWorkplaceI18n = error => {
  if (error === 'Ya tienes una reserva de escritorio para este dia')
    return t('there is already a reservation for this day')

  if (error === 'La cantidad de espacios asignados al grupo se ha superado')
    return t('the number of slots allocated to the group has been exceeded')

  if (error === 'Entry time La hora de ingreso supera el mínimo de tiempo previo a reservar')
    return t('the check-in time exceeds the minimum time prior to booking')

  if (error === 'Entry time No se puede crear la reserva para tiempo superado')
    return t('cannot create reservation for time exceeded')

  if (error === "translation missing: es.activerecord.errors.models.workplace_room_reservation.attributes.entry_time.gt_reservation_time")
    return t('The check-in time cannot be less than the current time')

  return error
}

const lngDictionary = lng => {
  if (lng === 'es-ES') return 'es'
  if (lng === 'en-EN') return 'en'
  return lng
}

const getReasons = () => [
  t('private meeting'),
  t('workday'),
  t('training'),
  t('special event'),
  t('others')
]

const parseReason = reason => {
  if (reason === 'Reunión particular' || reason === 'Private meeting') return t('private meeting')
  else if (reason === 'Evento especial' || reason === 'Special event') return t('special event')
  else if (reason === 'Jornada Laboral' || reason === 'Workday') return t('workday')
  else if (reason === 'Capacitación' || reason === 'Training') return t('training')
  return t('others')
}

export {
  reasonI18n,
  getReasons,
  parseReason,
  lngDictionary,
  getAllLangText,
  reasonI18nReverse,
  reasonWorkplaceI18n,
  reasonWorkplaceI18nReverse,
  errorCreateReservationI18n,
  errorCreateReservationWorkplaceI18n
}