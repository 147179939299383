import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import { PersistGate } from 'redux-persist/integration/react'
import './fonts/AvenirLTStd-Medium.otf'
import 'normalize.css'
import './index.sass'
import './i18n'
import configureStore from './state/store'
import RoutingSystem from './routes'
import theme from './theme'
import { lngDictionary } from './common/utilities/Dictionary'
import GlobalSnackbar from './common/components/GlobalSnackbar/GlobalSnackbar'
import GlobalBackdrop from './common/components/GlobalBackdrop/GlobalBackdrop'
import NetworkDetector from './common/components/NetworkDetector/NetworkDetector'
import ErrorBoundary from './common/components/ErrorBoundary/ErrorBoundary'
import { Settings } from "luxon"

const { store, persistor } = configureStore();

(function () {
  let lng = localStorage.getItem('lng')
  if (lng) Settings.defaultLocale = lng
  else {
    lng = navigator.language || navigator.userLanguage
    localStorage.setItem('lng', lngDictionary(lng))
    Settings.defaultLocale = lngDictionary(lng)
  }
})()

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <RoutingSystem />
            <NetworkDetector />
            <GlobalSnackbar />
            <GlobalBackdrop />
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)



