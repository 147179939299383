import React from 'react'
import { styled } from '@mui/material/styles'
import { grey } from '@mui/material/colors'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import CircularProgress from '@mui/material/CircularProgress'
import Popover from '@mui/material/Popover'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import {
  useMediaQuery
} from '@mui/material'

import TimePicker from '../TimePicker/TimePicker'

import { useTheme } from '@mui/material/styles'


import "./SwipeableTimePicker.sass"

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ?
      grey[100] :
      theme.palette.background.default,
}));

function SwipeableTimePicker(props) {
  const { t } = useTranslation();

  const defaultFunction = () => { }
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))

  const {
    type,
    minTime,
    message = '',
    open = false,
    time = new Date(),
    exitTime = null,
    anchorEl = window.document.body,
    renderMessage,
    allTime = true,
    loading = false,
    setOpen = defaultFunction,
    setTime = defaultFunction,
    onContinue = defaultFunction,
    setAllTime = defaultFunction,
    setExitTime = defaultFunction,
    onClose = props.setOpen ? () => props.setOpen(false) : defaultFunction,
  } = props

  const id = open ? 'simple-popover' : undefined

  const getSwipeableTimePicker = timePicker => {
    return (
      <Root>
        <SwipeableDrawer
          className={`swipeable-timepicker ${type}`}
          anchor="bottom"
          open={open}
          onClose={onClose}
          onOpen={() => setOpen(true)}
          disableSwipeToOpen={false}
        >
          {timePicker}
        </SwipeableDrawer>
      </Root>
    )
  }

  const getPopoverTimePicker = timePicker => {
    return (
      <Popover
        className="popover"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        {timePicker}
      </Popover>
    )
  }

  const getTimePickerStyle = timePicker => {
    if (isLarge) return getPopoverTimePicker(timePicker)
    return getSwipeableTimePicker(timePicker)
  }

  const getDisabled = () => {
    return (
      loading ||
      props.isDisabled
    )
  }

  const getRangeTimePicker = () => {
    return <div className='RangeTimePicker'>
      <FormControlLabel
        control={
          <Checkbox
            className='all-time'
            checked={allTime}
            onChange={e => setAllTime(e.target.checked)}
            size="small"
          />}
        label={t('all day')}
      />
      <FormControlLabel
        labelPlacement="start"
        onClick={e => e.preventDefault()}
        control={
          <TimePicker
            disabled={allTime}
            minTime={minTime}
            setTime={setTime}
            time={time}
          />
        }
        label={`${t("from")}:`}
      />
      <FormControlLabel
        labelPlacement="start"
        onClick={e => e.preventDefault()}
        control={
          <TimePicker
            disabled={allTime}
            minTime={time}
            setTime={setExitTime}
            time={exitTime}
          />
        }
        label={`${t("To")}:`}
      />

    </div>
  }

  const getBasicTimePicker = () => {
    return <TimePicker
      minTime={minTime}
      setTime={setTime}
      time={time}
    />
  }

  const getTimePickerType = () => {
    if (type === 'range') return getRangeTimePicker()
    return getBasicTimePicker()
  }

  return (
    getTimePickerStyle(
      <div className="SwipeableTimePicker">
        {getTimePickerType()}
        <div className="calendar-footer">
          {
            renderMessage ?
              renderMessage() :
              <p className="message">{message}</p>
          }
          <Stack spacing={2} direction="row" justifyContent="center">
            <Button
              className="footer-bottom"
              fullWidth
              variant="outlined"
              disabled={getDisabled()}
              onClick={() => onContinue ? onContinue() : setOpen(false)} >
              {loading ? (
                <CircularProgress
                  size={24}
                />
              ) : t('continue')}
            </Button>
          </Stack>
        </div>
      </div>
    )
  );
}

export default SwipeableTimePicker;
